import React from "react";
import { Box, Typography } from "@mui/material";
import clock from "assets/icons/clock2.svg";

const ProgressBar = ({ hoursRemaining, totalHours }) => {
  // Convert minutes to hours
  const remainingHours = hoursRemaining / 60;
  const totalHoursInHours = totalHours / 60;

  const hoursLeft = remainingHours.toFixed(0);

   // If hoursLeft is negative, don't display it
   if (hoursLeft < 0) {
    return null; 
  }

  // Calculate progress
  const progress =
    ((totalHoursInHours - remainingHours) / totalHoursInHours) * 100;

  // console.log("progress:", progress);
  return (
    <Box className="progress-bar-wrapper">
      <Box
        className="progress-bar-wrapper__progress"
        sx={{
          width: `${progress}%`,
        }}
      />
      <Typography className="progress-bar-wrapper__text" variant="body2">
        <Box
          component="img"
          src={clock}
          className="progress-bar-wrapper__icon"
        />
        {hoursLeft} Hrs Remaining
      </Typography>
    </Box>
  );
};

export default ProgressBar;
