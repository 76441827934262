import { Card, Typography } from "@mui/material";
import React from "react";

const HtmlLoginCard = ({question, answer}) => {
  const cleanHtmlContent = answer.replace(/&nbsp;/g, ' ');
  return (
    <Card className="html-login-card">
      <Typography variant="h6" className="html-login-card__title">
        {question}
      </Typography>
      <Typography variant="text" className="html-login-card__para" dangerouslySetInnerHTML={{ __html: cleanHtmlContent }}>

      </Typography>
    </Card>
  );
};

export default HtmlLoginCard;
