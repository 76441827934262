import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { securityFormSchema } from "config/schemas/settingsProfileSchema";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { encryptData } from "utilityFunction/encryption&Decryption";
import UserContext from "context/userContext/UserContext";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import NotifyUserContext from "context/notifyUserContext/NotifyUserContext";

const Security = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(6);
  const [userInfo, setUserInfo] = useState(null);
  const [formStatus, setFormStatus] = useState({ success: false, error: false });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Notify user context
  const { notifyUser } = useContext(NotifyUserContext);
  // User context
  const { userData, isLoading } = useContext(UserContext);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Post API request
  const {
    data: updatePassword,
    loading: updatingPassword,
    error: errorUpdatingPassword,
    postData: updatePasswordPayload,
  } = usePostRequest(API_ENDPOINTS.RESET_PASSWORD);

  const redirectToLogin = () => {
    setModalOpen(false);
    // console.log("Redirecting to the login page");

    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  };

  const handlePasswordChangeSuccess = () => {
    setModalOpen(true);
    // console.log("Password changed successfully");
    localStorage.clear();

    let timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          redirectToLogin();
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!isLoading && userData && userData.length > 0) {
      const userObject = userData.find(
        (item) => typeof item === "object" && "user_id" in item
      );
      if (userObject && userObject !== userInfo) {
        setUserInfo(userObject);
        // console.log("User info set:", userObject);
      }
    }
  }, [userData, isLoading]);

  // Handle form submit function
  const onSubmit = async (values, actions) => {
    try {
      // Encrypt the passwords
      const encryptedOldPassword = encryptData(values.currentPassword);
      const encryptedNewPassword = encryptData(values.newPassword);

      // Send POST request payload to update password
      const response = await updatePasswordPayload({
        oldPassword: encryptedOldPassword,
        newPassword: encryptedNewPassword,
      });

      // Check if the password update was successful
      if (updatePassword && updatePassword.message && !updatingPassword) {
        notifyUser(
          "Password Changed",
          "Password successfully changed.",
          "success"
        );
        setFormStatus({ success: true, error: false });
        // console.log("Password successfully changed");
        handlePasswordChangeSuccess();
        actions.resetForm();
      } else {
        notifyUser("Update Failed", "Current password is incorrect.", "error");
        setFormStatus({ success: false, error: true });
        // console.log("Password update failed");
      }
    } catch (err) {
      console.error("Error while updating password:", err);
      notifyUser("Error", "There was an error updating the password.", "error");
      setFormStatus({ success: false, error: true });
    }
  };

  // Formik setup
  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: securityFormSchema,
    onSubmit,
  });

  return (
    <Box className="security-wrapper">
      <Typography
        component="h1"
        variant="h5"
        className="security-wrapper__header text"
      >
        Security
      </Typography>
      <Box
        className="security-form"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <InputLabel className="security-form__input-label text">
              Current Password
            </InputLabel>
            <TextField
              placeholder="••••••••"
              size="small"
              name="currentPassword"
              required
              fullWidth
              id="currentPassword"
              type={showPassword ? "text" : "password"}
              value={values.currentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  color: "#667085",
                  fontFamily: "Gilroy-Regular, sans-serif",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ mr: 2, width: "50%" }} />
                      ) : (
                        <Visibility sx={{ mr: 2, width: "50%" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.currentPassword && touched.currentPassword && (
              <Typography className="error-input_login">
                {errors.currentPassword}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="security-form__input-label text">
              New Password
            </InputLabel>
            <TextField
              placeholder="••••••••"
              size="small"
              required
              fullWidth
              type="password"
              id="newPassword"
              name="newPassword"
              autoComplete="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  color: "#667085",
                  fontFamily: "Gilroy-Regular, sans-serif",
                },
              }}
            />
            {errors.newPassword && touched.newPassword && (
              <Typography className="error-input_login">
                {errors.newPassword}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="security-form__input-label text">
              Confirm Password
            </InputLabel>
            <TextField
              placeholder="••••••••"
              size="small"
              required
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              autoComplete="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  color: "#667085",
                  fontFamily: "Gilroy-Regular, sans-serif",
                },
              }}
            />
            {errors.confirmPassword && touched.confirmPassword && (
              <Typography className="error-input_login">
                {errors.confirmPassword}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start">
          <Grid item mt={4}>
            <Button
              className="security-form__button"
              variant="outlined"
              disabled={isSubmitting || updatingPassword}
              type="submit"
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="session-expiry-modal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="session-expiry-modal__heading"
          >
            Password Changed Successfully
          </Typography>
          <Typography
            id="modal-modal-description"
            className="session-expiry-modal__description"
            sx={{ mt: 2 }}
          >
            Redirecting to login page in {countdown} seconds
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default Security;
