import { API_ENDPOINTS } from 'config/ApiConfig';
import React, { createContext, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';

const SocketContext = createContext();


export const SocketProvider = ({ children }) => {
  const socket = useRef();

  useEffect(() => {
    socket.current = io(API_ENDPOINTS.SOCKET_URL);
  
    socket.current.on('connect', () => {
      console.log('Socket connected:', socket.current.id);
    });
  
    socket.current.on('disconnect', () => {
      console.log('Socket disconnected');
    });
  
    return () => {
      socket.current.disconnect();
    };
  }, []);
  

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => React.useContext(SocketContext);
