import React from "react";
import { Box, Rating, Typography } from "@mui/material";
import ProfilePicture from "components/common/profilePicture/ProfilePicture";

const CourseReviewCard = ({
  firstName,
  lastName,
  imageUrl,
  rating,
  reviewText,
  addedDate,
  imageSize = 65,
}) => {
  return (
    <Box className="course-review-card-wrapper">
      <Box className="course-review-card-wrapper__user">
        <ProfilePicture
          className="profile-form__avatar"
          firstName={firstName}
          lastName={lastName}
          imageSize={imageSize}
          imageUrl={imageUrl ? imageUrl : ""}
        />
        <Box className="course-review-card-wrapper__user--details">
          <Typography className="course-review-card-wrapper__user--details-name">
            {`${firstName}${" "}${lastName}`}
          </Typography>
          <Box display="flex" alignItems="center">
            <Rating
              value={rating}
              readOnly
              size="small"
              name="size-small"
              className="course-review-card-wrapper__user--details-rating"
            />
            {addedDate && (
              <Typography
                className="course-review-card-wrapper__added"
                style={{ marginLeft: "8px" }}
              >
                {addedDate}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Typography className="course-review-card-wrapper__review">
        {reviewText}
      </Typography>
    </Box>
  );
};

export default CourseReviewCard;
