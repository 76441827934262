import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { HiOutlineCheckCircle } from "react-icons/hi";
export const CouponSuccessMessage = ({ couponCode, onClose }) => (
  <Box className="apply-coupon">
    <Box className="apply-coupon_wrapper">
      <HiOutlineCheckCircle className="apply-coupon_wrapper--check-icon" />
      <Typography variant="body2" className="apply-coupon_wrapper--text">
        <Typography className="apply-coupon_wrapper--coupon-code">
          {couponCode}
        </Typography>{" "}
        Applied Successfully
      </Typography>
    </Box>
    <IconButton size="small" onClick={onClose}>
      <ClearIcon className="apply-coupon_wrapper--x-icon" />
    </IconButton>
  </Box>
);
