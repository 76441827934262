import { Box, Grid, Typography, Skeleton, Avatar } from '@mui/material';

const CertificateCardSkeleton = () => {
    return (
        <Box className="certificate-container-card">
            <Grid container spacing={4}>
                {/* Certificate Verification Header */}
                <Grid item xs={12}>
                    <Skeleton variant="text" width="40%" height={40} />
                </Grid>

                {/* Main certificate preview and recipient details */}
                <Grid item xs={12} md={8} className='certificate-preview-card'>
                    {/* Certificate Preview Skeleton */}
                    <Box className="certificate-preview" sx={{
                        overflow: 'hidden', 
                        maxWidth: '100%',
                    }}>
                        <Skeleton variant="rectangular" height={300} width="100%" />
                    </Box>

                    {/* Download and Share Buttons Skeleton */}
                    <Grid item xs={12} className="action-buttons">
                        <Skeleton variant="rectangular" width={100} height={40} sx={{ marginRight: 2 }} />
                        <Skeleton variant="rectangular" width={100} height={40} />
                    </Grid>

                    {/* Description Skeleton */}
                    <Grid item xs={12} className='description'>
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="70%" />
                    </Grid>
                </Grid>

                {/* Recipient and course details */}
                <Grid item xs={12} md={4}>
                    {/* Recipient Details Skeleton */}
                    <Box mb={2}>
                        <Skeleton variant="text" width="60%" height={30} />
                        <Grid container spacing={2} alignItems="center" sx={{ marginTop: 1, marginBottom: 3 }}>
                            <Grid item>
                                <Skeleton variant="circular" width={60} height={60} />
                            </Grid>
                            <Grid item>
                                <Skeleton variant="text" width={100} />
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Course Details Skeleton */}
                    <Box>
                        <Skeleton variant="text" width="50%" height={30} />
                        <Grid mt={2}>
                            <Skeleton variant="rectangular" height={80} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CertificateCardSkeleton;
