import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import CourseCompletionProgressBar from "components/module/app/courseChapter/CourseCompletionProgressBar";
import CourseTimeline from "components/module/app/courseChapter/Timeline";
import chevron from "assets/icons/chevron-down.svg";
import LessonContext from "context/lessonContext/LessonContext";
import { handleDownload } from "utilityFunction/donwloadFile";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import useGetRequest from "hooks/useGetRequest";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";

const CourseTimelineCard = () => {
  const { chapter } = useParams();
  const { sortedLessonData } = useContext(LessonContext);
  const [totalLessons, setTotalLessons] = useState(0);
  const [completedLessons, setCompletedLessons] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  const [certificateUrl, setCertificateUrl] = useState("");
  const [certificateDetails, setCertificateData] = useState("");
  const [hasFetched, setHasFetched] = useState(false);
  const { notifyUser } = useContext(NofityUserContext);

  const {
    data: createCertificate,
    error: createCertificaterError,
    loading: loadingcreateCertificate,
    postData: postCreateCertificate,
  } = usePostRequest(API_ENDPOINTS.CREATE_COURSE_COMPLETITION_CERTIFICATE);

  const {
    data: certificateData,
    loading: loadingCertificateData,
    error: certificateDataError,
    getData: certificateDataPayload,
  } = useGetRequest(API_ENDPOINTS.COURSE_CERTIFICATE_DETAILS);

  useEffect(() => {
    if (sortedLessonData) {
      const totalLessons = sortedLessonData.reduce((acc, chapter) => acc + chapter.lessons.length, 0);
      const completedLessons = sortedLessonData.reduce((acc, chapter) => {
        return acc + chapter.lessons.filter(lesson => lesson.isCompleted).length;
      }, 0);

      const completionPercentage = totalLessons > 0 ? Math.round((completedLessons / totalLessons) * 100) : 0;

      setTotalLessons(totalLessons);
      setCompletedLessons(completedLessons);
      setCompletionPercentage(completionPercentage);
    }
  }, [sortedLessonData]);

  const [isGenerating, setIsGenerating] = useState(false);
  const fetchedRef = useRef(false);

  const generateCertificate = async () => {
    try {
      const payLoad = {
        slug: chapter
      }
      setIsGenerating(true);
      await postCreateCertificate(payLoad);
      setIsGenerating(false);
      fetchedRef.current = false;  // Mark the API as called
      setHasFetched(false);

    } catch (error) {
      console.error("Error fetching certificate data:", error);
    }
  };

  useEffect(() => {
    if (loadingcreateCertificate && !createCertificate) {
      notifyUser("Hang tight!", "Your certificate is being generated. Please give us a moment.", "success");
      return;
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!fetchedRef.current && completionPercentage === 100) {
          await certificateDataPayload(chapter);
          fetchedRef.current = true;  // Mark the API as called
          setHasFetched(true);  // Update state (optional)
        }
      } catch (error) {
        console.error("Error fetching certificate data:", error);
      }
    };

    if (completionPercentage === 100 && !fetchedRef.current) {
      fetchData();
    }
  }, [completionPercentage, chapter, certificateDataPayload]);

  useEffect(() => {
    if (!loadingCertificateData && certificateData) {
      setHasFetched(false);
      setCertificateData(certificateData);

      if (certificateData.courseCertificate && Object.keys(certificateData.courseCertificate).length) {
        setCertificateUrl(`${API_ENDPOINTS.DOWNLOAD_CERTIFICATE}/${certificateData.courseCertificate.certificate_code}.pdf`)
      }
    }
  }, [certificateData, loadingCertificateData]);

  return (
    <Box className="course-timeline">
      <Box className="course-timeline__progress-wrapper">
        <Typography className="course-timeline__progress-wrapper--title">
          Course Completion
        </Typography>
        <Box className="course-timeline__progress-wrapper--progress-details">
          <Typography className="course-timeline__progress-wrapper--progress-details--percentage">
            {completionPercentage}% Completed
          </Typography>
          <Typography className="course-timeline__progress-wrapper--progress-details--chapter">
            {completedLessons}/{totalLessons}
          </Typography>
        </Box>
        <CourseCompletionProgressBar progress={completionPercentage} />
      </Box>
      <CourseTimeline />
      <Box>
        {completionPercentage === 100 && (
          certificateDetails.courseCertificate && Object.keys(certificateDetails.courseCertificate).length > 0 ? (
            <Link
              className="course-timeline__back-button--link course-timeline__back-button--text"
              onClick={() => handleDownload(certificateUrl)}
              disabled={isGenerating} // Optional: Disable while generating
            >
              Download
            </Link>
          ) : (
            <Link
              className="course-timeline__back-button--link course-timeline__back-button--text"
              onClick={generateCertificate}
              disabled={isGenerating} // Optional: Disable while generating
            >
              {isGenerating ? "Generating..." : "Generate Certificate"}
            </Link>
          )
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box className="course-timeline__back-button">
          <Link lassName="course-timeline__back-button--link" to={`/app/course/${chapter}/learn`}>
            <Box
              component="img"
              src={chevron}
              alt="chevron"
              className="course-timeline__back-button--link-icon"
            />
          </Link>
          <Link lassName="course-timeline__back-button--link" to={`/app/course/${chapter}/learn`}>
            <Typography className="course-timeline__back-button--text">
              Back to Course Dashboard
            </Typography>
          </Link>

        </Box>
      </Box>

    </Box>
  );
};

export default CourseTimelineCard;
