import React from "react";
import { Box, Button, Typography } from "@mui/material";
// import playicon from "assets/icons/Play button.svg";
import person from "assets/images/helpCenterImage.svg";

const ContactUsCard = () => {
  return (
    <Box className="contact-us-wrapper">
      <Box className="contact-us-wrapper__image-wrapper">
        <Box
          component="img"
          src={person}
          alt="help center"
          className="contact-us-wrapper__image-wrapper--image"
        />
        {/* <Box
          component="img"
          src={playicon}
          alt="play icon"
          className="contact-us-wrapper__image-wrapper--icon"
        /> */}
      </Box>
      <Box className="contact-us-wrapper__details-wrapper">
        <Typography
          variant="body2"
          className="contact-us-wrapper__details-wrapper--title"
        >
          Resolve Issue faster with our amazing support team
        </Typography>
        <Typography
          variant="body2"
          className="contact-us-wrapper__details-wrapper--caption"
        >
          Need help with something which you couldn’t find it here
        </Typography>
        <Button
          variant="outlined"
          className="contact-us-wrapper__details-wrapper--button"
          onClick={() => window.location.href = "https://mytrueskills.com/index#contact"} 
        >
          Contact Us
        </Button>
      </Box>
    </Box>
  );
};

export default ContactUsCard;
