import { useState, useEffect, useContext } from "react";
import CourseViewContext from "context/courseViewContext/CourseViewContext";

const useSortedCourseData = (courseData) => {
  const [
    course_name,
    course_difficulty,
    course_thumbnail,
    courseStat = {},
    status,
    chapterDetails = [],
    userLessonProgress = [],
  ] = courseData;

  // Save the data in the course view context
  const { setCourseData, setTransformedData } = useContext(CourseViewContext);

  useEffect(() => {
    // Store the course data in the context
    setCourseData({
      course_name,
      course_difficulty,
      course_thumbnail,
      courseStat,
      status,
    });
  }, [courseData]);

  const [sortedCourseData, setSortedCourseData] = useState([]);
  const [totalLessons, setTotalLessons] = useState(0);
  const [completedLessons, setCompletedLessons] = useState(0);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);

  useEffect(() => {
    const completionMap = userLessonProgress.reduce((acc, progress) => {
      acc[progress.lesson_id] = {
        completed: progress.completed,
        time_spent: (acc[progress.lesson_id]?.time_spent || 0) + progress.time_spent,
      };
      return acc;
    }, {});

    let activeChapterFound = false;
    let totalLessonCount = 0;
    let completedLessonCount = 0;
    let totalTimeSpent = 0;
    let activeChapter = null;
    let activeLesson = null;

    const updatedChapters = chapterDetails
      .sort((a, b) => a.chapter_order - b.chapter_order)
      .map((chapter) => {
        const sortedLessons = chapter.lessons.sort(
          (a, b) => a.lesson_order - b.lesson_order
        );

        let allLessonsCompleted = true;
        let chapterHasActiveLesson = false;

        const updatedLessons = sortedLessons.map((lesson) => {
          const lessonCompletion = completionMap[lesson.lesson_id] || {};
          const isCompleted = !!lessonCompletion.completed;

          totalLessonCount++;
          if (isCompleted) completedLessonCount++;

          if (!isCompleted) {
            allLessonsCompleted = false;
          }

          let isActive = false;

          if (!activeChapterFound && !chapterHasActiveLesson && !isCompleted) {
            isActive = true;
            chapterHasActiveLesson = true;
            activeChapterFound = true;
            activeChapter = chapter;
            activeLesson = lesson;
          }

          totalTimeSpent += lessonCompletion.time_spent || 0;

          return {
            ...lesson,
            isCompleted,
            isLocked: !isCompleted && !isActive,
            isActive,
          };
        });

        return {
          ...chapter,
          lessons: updatedLessons,
          isCompleted: allLessonsCompleted,
          isActive: chapterHasActiveLesson,
        };
      });

    const sortedChapters = updatedChapters.sort(
      (a, b) => a.chapter_order - b.chapter_order
    );

    setSortedCourseData(sortedChapters);
    setTotalLessons(totalLessonCount);
    setCompletedLessons(completedLessonCount);
    setCompletionPercentage(
      totalLessonCount > 0 ? (completedLessonCount / totalLessonCount) * 100 : 0
    );
    setTotalTimeSpent(totalTimeSpent);

    // Set transformed data in the context
    setTransformedData({
      activeChapter: activeChapter || null,
      activeLesson: activeLesson || null,
      timeOfIncompleteLessons: sortedChapters.reduce(
        (total, chapter) => total + chapter.lessons
          .filter(lesson => !lesson.isCompleted)
          .reduce((chapterTotal, lesson) => chapterTotal + lesson.estimated_completion_time, 0),
        0
      ),
      timeSpent: totalTimeSpent,
      totalLessons: totalLessonCount,
      completedLessons: completedLessonCount,
    });
  }, [chapterDetails, userLessonProgress, setTransformedData]); // Added setTransformedData to the dependency array

  return {
    sortedCourseData,
    totalLessons,
    completedLessons,
    completionPercentage,
    totalTimeSpent,
  };
};

export default useSortedCourseData;
