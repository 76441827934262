import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineContent from "@mui/lab/TimelineContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clock from "assets/icons/clock2.svg";
import lesson from "assets/icons/lesson.svg";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { useParams } from "react-router-dom";
import LoadingContext from "context/loadingContext/LoadingContext";
import useSortedChapters from "hooks/useSortedChapter";
import { formatDuration } from "utilityFunction/convertMinToHours";
import SkeletonList from "components/common/skeleton/SkeletonCard";

const CurriculumCard = () => {
  //store the slug in a var
  const { slug } = useParams();
  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");
  const [loading, setLoading] = useState(true);

  //store the course chapters details
  const [courseChapter, setCourseChapter] = useState([]);
  //loading global context
  const { isAppLoading } = useContext(LoadingContext);

  //store the slub in a usestate
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
    }
  }, [slug]);

  //trigger the get req for the chapters timeline
  const {
    data: chapterData,
    error: chapterError,
    loading: loadingChapter,
    getData: chapterPayload,
  } = useGetRequest(API_ENDPOINTS.COURSE_CHAPTER);

  useEffect(() => {
    const fetchChapter = async () => {
      if (storeSlug) {
        try {
          await chapterPayload(storeSlug);
        } catch (err) {
          // console.log("error with the get request api call");
        }
      }
    };
    fetchChapter();
  }, [storeSlug]);

  useEffect(() => {
    if (!loadingChapter) {
      if (chapterError) {
        // console.log("error fetching the chapter error:", chapterError);
      } else if (chapterData) {
        //setting the data from the get api to the local state
        setCourseChapter(chapterData);
      }
    }
  }, [chapterData, loadingChapter, chapterError]);

  useEffect(() => {
    // Set a timeout to stop showing the skeleton after a while
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout duration as needed (e.g., 3 seconds)

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);

  useEffect(() => {
    // Set a timeout to stop showing the skeleton after a while
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout duration as needed (e.g., 3 seconds)

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);

  //custom hook to sort the chapter and lesson based on the order
  const sortedChapters = useSortedChapters(courseChapter);
  // console.log("sorted chapter", sortedChapters);
  return sortedChapters.length > 0 ? (
    <Box className="curriculum-card">
      <Typography variant="h5" className="curriculum-card__title">
        Curriculum
      </Typography>
      <Box className="curriculum-card__timeline-wrapper">
        {sortedChapters.map((chapterItem, index) => (
          <Accordion
            key={index}
            className="curriculum-card__timeline-wrapper__accordian"
          >
            <AccordionSummary
              className="curriculum-card__timeline-wrapper__accordian--summary"
              id={`panel${index}-header`}
              aria-controls={`panel${index}-content`}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography
                className="curriculum-card__timeline-wrapper--title"
                variant="body2"
              >
                {chapterItem.chapter_name}
              </Typography>
              <Box className="curriculum-card__timeline-wrapper__heading">
                <Box
                  component="img"
                  src={lesson}
                  className="curriculum-card__timeline-wrapper__heading--icon"
                />
                <Typography
                  className="curriculum-card__timeline-wrapper__heading--text"
                  id="lesson"
                  variant="body2"
                >
                  {`${chapterItem.lessons.length} lesson`}
                </Typography>
                <Box
                  component="img"
                  src={clock}
                  className="curriculum-card__timeline-wrapper__heading--icon"
                  id="clock"
                />
                <Typography
                  className="curriculum-card__timeline-wrapper__heading--text"
                  variant="body2"
                >
                  {`${formatDuration(chapterItem.estimated_completion_time)}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="curriculum-card__timeline-wrapper__accordian--details">
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {chapterItem.lessons.map((lessonItem, lessonIndex) => (
                  <React.Fragment key={lessonItem.lesson_id}>
                    <TimelineItem className="curriculum-card-timeline__item">
                      <TimelineContent className="curriculum-card-timeline__item--content">
                        <Box className="curriculum-card-timeline-content-wrapper">
                          {/* <Link
                            to={`/app/course/${storeSlug}/learn/${lessonItem.lesson_name
                              .toLowerCase()
                              .replace(/ /g, "-")}`}
                            className="curriculum-card-timeline-content-wrapper__link"
                          > */}
                          <Typography className="curriculum-card-timeline-content-wrapper__text">
                            {lessonItem.lesson_name}
                          </Typography>
                          {/* </Link> */}
                          <Typography
                            variant="body2"
                            className="curriculum-card-timeline-content-wrapper__duration"
                          >
                            {`${lessonItem.estimated_completion_time}:00`}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                    {lessonIndex < chapterItem.lessons.length - 1 && (
                      <Divider
                        sx={{
                          backgroundColor: "#f5faff",
                          width: "95%",
                          borderColor: "rgba(0, 0, 0, 0.09)",
                          borderBottomWidth: "1px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  ) : loading ? (
    <SkeletonList />
  ) : (
    <Typography></Typography>
  );
};

export default CurriculumCard;
