import React from "react";
import { Box } from "@mui/material";
import MCQ from "components/courseComponents/quizComp/MCQ";
import { Header } from "components/common/header/Header";

const MCQLessonPage = () => {
  return (
    <Box className="MCQ-page">
      <Header title="Assessment Quiz" />
      <MCQ />
    </Box>
  );
};

export default MCQLessonPage;
