import React from "react";
import ReportContentHeader from "components/module/app/reportContent/ReportContentHeader";
import ReportContentForm from "components/module/app/reportContent/ReportContentCard";

const ReportContentPage = () => {
  return (
    <>
      <ReportContentHeader />
      <ReportContentForm />
    </>
  );
};

export default ReportContentPage;
