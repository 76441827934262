import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import EnrolledCourseCard from "components/module/app/myCourses/EnrolledCourseCard";
import { API_ENDPOINTS } from "config/ApiConfig";

// const EnrolledCourseData = [
//   {
//     id: 1,
//     courseName: "Course Name",
//     level: "Level One",
//     image: image,
//     status: "in_progress",
//     progress: 50,
//   },
//   {
//     id: 2,
//     courseName: "MTS Life Skills",
//     level: "Level Three",
//     image: image,
//     status: "not_started",
//   },
//   {
//     id: 3,
//     courseName: "MTS Life Skills",
//     level: "Level Two",
//     image: image,
//     status: "completed",
//   },
// ];

const EnrolledCourses = ({ enrolledCoursesDetails }) => {
  return (
    <Box>
      <Typography
        component="p"
        className="enrolled-courses-main-title text"
        mb={2}
      >
        Enrolled Courses
      </Typography>
      <Grid container columnSpacing={1} rowSpacing={4}>
        {enrolledCoursesDetails.map((course) => (
          <Grid item xs={12} sm={6} md={3} key={course.course_id}>
            <EnrolledCourseCard
              courseName={course.MasterCourse.course_name}
              level={`Level ${course.difficulty}`}
              image={`${API_ENDPOINTS.GET_IMAGE}/${course.MasterCourse.MasterImage.name}`}
              status={course.status}
              progress={course.progress || 10}
              slug={course.MasterCourse.slug}
              certificate={course.certificateCode}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EnrolledCourses;
