import CreateAccount from "components/module/auth/CreateAccount";
import ForgetPwdPage from "pages/authPage/ForgetPwdPage";
import LoginPage from "pages/authPage/LoginPage";
import ResetPasswordPage from "pages/authPage/ResetPasswordPage";

export const authRoutes = [
  {
    path: "",
    element: <LoginPage />,
    index: true,
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "create-account",
    element: <CreateAccount />,
  },
  {
    path: "forgot-password",
    element: <ForgetPwdPage />,
  },
  {
    path: "reset-password/:token",
    element: <ResetPasswordPage />,
  },
];
