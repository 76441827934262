import React, { useContext, useEffect, useState } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { Box, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { FiMessageCircle } from "react-icons/fi";
import { FiCheck } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { FiXCircle } from "react-icons/fi";
import { FiAlertTriangle } from "react-icons/fi";

const iconMap = {
  info: FiMessageCircle,
  warning: FiAlertTriangle,
  success: FiCheck,
  error: FiXCircle,
};

export default function CustomizedSnackbars() {
  //nofity user context
  const { message, notifyUser, clearUserNotification } =
    useContext(NofityUserContext);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    clearUserNotification();
  };
  if (!message || !message.title) return null;
  const IconComponent = iconMap[message.type] || FiMessageCircle;

  return (
    <div>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={2000}
      >
        <Box className={`snackbar-wrapper-${message.type}`}>
          <Box className={`snackbar-wrapper-${message.type}__icon-wrapper`}>
            <IconComponent
              className={`snackbar-wrapper-${message.type}__icon-wrapper--icon`}
            />
          </Box>
          <Box className={`snackbar-wrapper-${message.type}__content-wrapper`}>
            <Typography
              className={`snackbar-wrapper-${message.type}__content-wrapper--title`}
            >
              {message.title}
            </Typography>
            <Typography
              className={`snackbar-wrapper-${message.type}__content-wrapper--caption`}
            >
              {message.caption}
            </Typography>
          </Box>
          <IoCloseOutline
            className={`snackbar-wrapper-${message.type}__close-icon`}
            onClick={handleClose}
          />
        </Box>
      </Snackbar>
    </div>
  );
}
