import LessonContext from "context/lessonContext/LessonContext";
import React, { useContext } from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ videoUrl, thumbnailUrl }) => {
  const { updateLessonCompletionData } = useContext(LessonContext);

  const handleProgress = ({ played, loaded }) => {
    const playedPercentage = played * 100; // Convert played fraction to percentage

    // Check if the video has played 50% or more
    // if (playedPercentage >= 50) {
    //   console.log("Video played 50% or more");
    //   updateLessonCompletionData({
    //     status: 1,
    //     elementId: 16,
    //     timeSpent: 4,
    //     isRequired: false,
    //   });
    // }
  };

  const handleEnded = () => {
    // console.log("Video has ended");
    // updateLessonCompletionData({
    //   status: 1,
    //   elementId: 16,
    //   timeSpent: 100,
    //   isRequired: false,
    // });
  };
  return (
    <ReactPlayer
      className="react-player"
      url={videoUrl}
      onProgress={handleProgress}
      onEnded={handleEnded}
      controls={false}
      style={{ borderRadius: "15px" }}
      width="100%"
      height="100%"
      light={
        <img
          src={thumbnailUrl}
          alt="Thumbnail"
          className="react-player__preview--image"
        />
      }
    />
  );
};

export default VideoPlayer;
