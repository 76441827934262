import { Box, Breadcrumbs, Typography } from "@mui/material";
import BasicBreadcrumbs from "components/common/breadcrumbs/BreakCrumbs";
import HtmlLoginCard from "components/module/app/helpCenter/helpCenterThree/HtmlLoginCard";
import HelpCenterHeader from "components/module/app/helpCenter/helpCenterTwo/HelpCenterHeader";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const HelpCenterPageThree = () => {
  const { categoryId, subCategoryId } = useParams();

  const [apiData, setApiData] = useState([]);

  const {
    data: helpCentre,
    loading: loadingHelpCentre,
    error: helpCentreError,
    getData: helpCentrePayLoad,
  } = useGetRequest(API_ENDPOINTS.HELP_CENTRE_CQUESTION);

  useEffect(() => {
    const fetchHelpCentre = async () => {
      const url = `${categoryId}/${subCategoryId}`;
      if (!helpCentre) {
        await helpCentrePayLoad(url);
      }
    };

    fetchHelpCentre();
  }, [helpCentre, helpCentrePayLoad]);

  useEffect(() => {
    if (!loadingHelpCentre && helpCentre) {
      setApiData(helpCentre);
    }
  }, [loadingHelpCentre, helpCentre]);

  // Ensure firstCategory is defined before accessing its properties
  const firstCategory = apiData.length > 0 ? apiData[0] : null;

  return (
    <Box className="help-center-page-three">
      <Breadcrumbs aria-label="breadcrumb">
      <Link to="/app/help-center-1" style={{ textDecoration: "none", color: "inherit" }}>
        Help Center
      </Link>
      <Link to="/app/help-center-1" style={{ textDecoration: "none", color: "inherit" }}>
      {firstCategory ? firstCategory.name : ""}
      </Link>

      {firstCategory ? (
        <Typography color="textPrimary">{firstCategory && firstCategory.subcategories.length > 0
          ? firstCategory.subcategories[0].name
          : ""}</Typography>
      ) : (
        <Typography color="textPrimary">Current Page</Typography>
      )}
    </Breadcrumbs>
      <HelpCenterHeader
        title={
          firstCategory && firstCategory.subcategories.length > 0
            ? firstCategory.subcategories[0].name
            : ""
        }
        description={
          firstCategory && firstCategory.subcategories.length > 0
            ? firstCategory.subcategories[0].description
            : ""
        }
        icon={
         firstCategory ? firstCategory.icon : ""
        }
      />
      {firstCategory &&
        firstCategory.subcategories &&
        firstCategory.subcategories.map(
          (subcategory) =>
            subcategory.questions &&
            subcategory.questions.map((question) => (
              <Box key={question.help_question_id}>
                <HtmlLoginCard
                  question={question.question}
                  answer={question.answer}
                />
              </Box>
            ))
        )}
    </Box>
  );
};

export default HelpCenterPageThree;
