import React from "react";
import { Box, Typography } from "@mui/material";
import userIcon from "assets/icons/user-01.svg";

const HelpCenterHeader = ({ title, description, icon }) => {
  return (
    <Box className="help-center-header">
      <Box className="help-center-header__icon-wrapper">
        <div className="help-center-header__icon-wrapper--icon" dangerouslySetInnerHTML={{ __html: icon }}>
        </div>
      </Box>
      <Box className="help-center-header__details">
        <Typography className="help-center-header__details--title" variant="h6">
          {title}
        </Typography>
        <Typography
          className="help-center-header__details--caption"
          variant="body2"
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default HelpCenterHeader;
