import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";

const CertificateCourseCard = ({
  courseDetails
}) => {
  //navigation var
  const navigate = useNavigate();
  const handleEnrollNow = () => {
    // Append the slug to the URL and navigate
    const courseDetailsUrl = `/app/course/${courseDetails.slug}/learn`;
    navigate(courseDetailsUrl);
  };


  return (
    <Box onClick={handleEnrollNow} sx={{ cursor: "pointer" }}>
      <Card
        className={`enrolled-course-card` }
      >
        <Box className="enrolled-course-card__wrapper">
          <CardMedia
            component="img"
            height="200"
            image={courseDetails.image}
            className="enrolled-course-card__card-media"
          />
          {/* <Typography className="enrolled-course-card__title text"></Typography> */}
        </Box>
        <CardContent sx={{ flexGrow: 1, paddingBottom: "4vh !important" }}>
          <Typography
            className="enrolled-course-card__course-name"
            variant="body1"
          >
            {courseDetails.courseName}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CertificateCourseCard;
