import React from 'react';
import { Box, CssBaseline, Grid } from '@mui/material';
import ChatArea from 'components/module/app/chatRoom/ChatArea';

const ChatRoomPage = () => {
  return (
    <>
      <Box sx={{ display: 'flex', height: '75vh', overflow: 'hidden' }}>
      <CssBaseline />
        <ChatArea />
    </Box>
    </>
  );
};

export default ChatRoomPage;
