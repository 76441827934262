import React from "react";
import { Box, Link, Typography } from "@mui/material";

const Topic = ({ name, description, icon }) => {

  return (
    <Box className="topic-wrapper">
      <Box className="topic-wrapper__icon-wrapper">
        <div className="topic-wrapper__icon-wrapper--icon"  dangerouslySetInnerHTML={{ __html: icon }}>
        </div>
      </Box>
      <Box className="topic-wrapper__details">
        <Typography className="topic-wrapper__details--title" variant="h6">
          <Link className="topic-wrapper__details--link">{name}</Link>
        </Typography>
        <Typography className="topic-wrapper__details--caption" variant="body2">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Topic;
