import { useMemo } from "react";

const useSortedChapters = (chapters) => {
  return useMemo(() => {
    return chapters
      .sort((a, b) => a.chapter_order - b.chapter_order)
      .map((chapter) => ({
        ...chapter,
        lessons: chapter.lessons.sort(
          (a, b) => a.lesson_order - b.lesson_order
        ),
      }));
  }, [chapters]);
};

export default useSortedChapters;
