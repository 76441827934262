import { Skeleton, Stack } from "@mui/material";
import React from "react";

const ReviewCardSkeleton = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rounded" width={320} height={120} />
      <Skeleton variant="rectangular" width={320} height={120} />
      <Skeleton variant="rounded" width={320} height={120} />
    </Stack>
  );
};

export default ReviewCardSkeleton;
