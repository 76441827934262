import CourseDetailsContext from "context/courseDetailsContext/CourseDetailsContext";
import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SkeletonList, {
  SkeletonOverview,
} from "components/common/skeleton/SkeletonCard";

const CourseOverviewCard = () => {
  const { courseInformation } = useContext(CourseDetailsContext);
  //state to store the overview
  const [overview, setOverview] = useState("");

  useEffect(() => {
    if (courseInformation) {
      setOverview(courseInformation.course_overview);
    } else {
      // console.log("data not found in the context");
    }
  }, [courseInformation]);
  return overview ? (
    <Box className="course-overview" id="overview">
      <Typography variant="h5" className="course-overview__title">
        Overview
      </Typography>

      <Typography
        variant="body1"
        className="course-overview__description"
        dangerouslySetInnerHTML={{ __html: overview.replace(
          /&nbsp;/g,
          " "
        ) }}
      />
    </Box>
  ) : (
    <SkeletonList />
  );
};

export default CourseOverviewCard;
