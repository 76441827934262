import React from "react";
import Login from "components/module/auth/Login";
const LoginPage = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default LoginPage;
