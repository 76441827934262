import { Box, Grid, Typography } from "@mui/material";
import ContactUsCard from "components/module/app/helpCenter/ContactUsCard";
import { SearchBarCard } from "components/module/app/helpCenter/SearchBarCard";
import Topic from "components/module/app/helpCenter/Topic";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const HelpCenterPageOne = () => {
  const [apiData, setApiData] = useState([]);

  const {
    data: helpCentre,
    loading: loadingHelpCentre,
    error: helpCentreError,
    getData: helpCentrePayLoad,
  } = useGetRequest(API_ENDPOINTS.HELP_CENTRE_CATEGORY);

  useEffect(() => {
    const fetchHelpCentre = async () => {
      if (!helpCentre) {
        await helpCentrePayLoad();
      }
    };

    fetchHelpCentre();
  }, [helpCentre, helpCentrePayLoad]);

  useEffect(() => {
    if (!loadingHelpCentre && helpCentre) {
      setApiData(helpCentre);
    }
  }, [loadingHelpCentre, helpCentre]);

  return (
    <Box className="help-center-page">
      <SearchBarCard />
      <Typography variant="h6" className="help-center-page__title">
        Browse by topic
      </Typography>
      <Grid container spacing={1}>
        {Array.isArray(apiData) && apiData.length > 0 ? (
          apiData.map((category) => (
            
            <Grid item xs={12} sm={4} md={3} key={category.help_category_id}>
              <Link to={`/app/help-center-2/${category.help_category_id}`}>
              <Topic
                name={category.name}
                description={category.description}
                icon={category.icon}
              />
            </Link>
            </Grid>
          ))
        ) : (
          <p>Loading or no data available...</p> // Optional: show a loading or empty message
        )}
      </Grid>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 10,
        }}
      >
        <ContactUsCard />
      </Box>
    </Box>
  );
};

export default HelpCenterPageOne;
