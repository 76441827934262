import { SkeletonLoader } from "components/common/skeleton/SkeletonCard";
import AvailableCourses from "components/module/app/myCourses/AvailableCourses";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import React, { useEffect, useState } from "react";

const CoursesCard = () => {
  const [coursesDetails, setCourseDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const {
    data: getCoursesData,
    loading: loadingCourses,
    error: errorFetchingCourses,
    getData: coursesPayload,
  } = useGetRequest(API_ENDPOINTS.PUBLIC_COURSE);

  // Fetch the courses data only once when the component mounts
  useEffect(() => {
    // Make sure that the function to get the data is called only once
    const fetchData = async () => {
      await coursesPayload(); // Only call this once during component mount
    };

    fetchData(); // Trigger API call

    // Empty dependency array to ensure it runs only once
  }, []);

  // Update courseDetails only when getCoursesData changes
  useEffect(() => {
    if (getCoursesData) {
  
      const mergedCourses = getCoursesData.availableCoursesDetails.map((course) => {
        // Find matching statistics by course_id
        const matchingStats = getCoursesData.CourseStat.find(
          (stat) => stat.course_id === course.course_id
        );
  
        // Merge course details with matching stats if found
        return {
          ...course,
          avg_review: matchingStats?.avg_review || 0, // Include stats or set to null if not found
        };
      });
  
      setCourseDetails(mergedCourses);
    }
  }, [getCoursesData]);
  

  useEffect(() => {
    if (coursesDetails) {
      setLoading(false); // Set loading to false when data is available
    }
  }, [coursesDetails]);

  useEffect(() => {

    // Add the Tawk.to script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = 'https://embed.tawk.to/673862314304e3196ae382b3/1icq3v56p';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (loading) {
    return <SkeletonLoader />;
  }
  return (
    <>
      {coursesDetails.length > 0 && (
        <AvailableCourses
          coursesText={"Courses"}
          availableCoursesDetails={coursesDetails}
        />
      )}
    </>
  );
};

export default CoursesCard;
