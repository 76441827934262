import React from "react";
import { Box, Typography } from "@mui/material";
import chevron from "assets/icons/chevron-right-accordian.svg";
import { Link, useNavigate } from "react-router-dom";

const SettingUpAccount = ({data , id}) => {
  const navigate = useNavigate();
  return (
    <>
       {data.map((subcategory) => (
        <Box key={subcategory.help_subcategory_id} className="setting-up-account-wrapper">

          <Typography variant="h6" className="setting-up-account-wrapper__title">
            {subcategory.name}
          </Typography>

          {subcategory.questions.map((question) => (
            <Box className="setting-up-account-wrapper__details-wrapper" key={question.help_question_id} >
                <Typography
                onClick={() => navigate(`/app/help-center-3/${id}/view/${question.help_question_id}`)}
                  variant="body2"
                  className="setting-up-account-wrapper__details-wrapper--text"
                >
                {question.question}
                </Typography>
      
                <Box
                  component="img"
                  src={chevron}
                  alt="chevron"
                  className="setting-up-account-wrapper__details-wrapper--icon"
                />
              </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

export default SettingUpAccount;
