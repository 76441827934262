import { Box, Skeleton } from "@mui/material";
import React from "react";

const ComponentSkeleton = () => {
  return (
    <Box 
    sx={{ 
      width: "100%", 
      maxWidth: 800, 
      margin: "20px auto",  // Combined margin and centered horizontally
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center",  // Center vertically if inside a flex container
      borderRadius: "10px",
      padding: "20px", // Optional for additional spacing around the skeleton
    }}
  >
    <Skeleton
      variant="rectangular"
      width="100%"
      sx={{ paddingTop: "56.25%", borderRadius: "22px" }}
    />
  </Box>
  
  );
};

export default ComponentSkeleton;
