import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export const Header = ({ title, subtitle }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box mb={4} className="header-container">
          <Typography
            variant="h5"
            component="h5"
            className="header-container-title capitalize"
            mb={1}
          >
            {title}
          </Typography>
          <Typography
            className="header-container-subtitle"
            variant="subtitle1"
            color="text.secondary"
          >
            {subtitle}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
