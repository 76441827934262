import React, { useContext, useEffect, useState } from "react";
import CourseDetailsContext from "context/courseDetailsContext/CourseDetailsContext";
import { Box, Button, Typography } from "@mui/material";
import { Grid, LinearProgress, Rating, Divider } from "@mui/material";
import CourseReviewCard from "components/module/app/courseDetails/CourseReviewCard";
import star from "assets/icons/star.svg";
import { API_ENDPOINTS } from "config/ApiConfig";
import { useParams } from "react-router-dom";
import useGetRequest from "hooks/useGetRequest";
import LoadingContext from "context/loadingContext/LoadingContext";
import { decryptData } from "utilityFunction/encryption&Decryption";
import SkeletonList from "components/common/skeleton/SkeletonCard";
import ReviewCardSkeleton from "components/common/skeleton/ReviewCardSkeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { formatDistanceToNow } from "date-fns";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay"; // Add autoplay CSS
import { FreeMode, Pagination, Autoplay } from "swiper/modules";

const calculateRatingPercentage = (rating, reviews) => {
  const totalReviews = reviews.length;
  const matchingReviews = reviews.filter(
    (review) => parseInt(review.rating) === rating
  ).length;
  const percentage = (matchingReviews / totalReviews) * 100;

  return Math.round(percentage); // Round to nearest whole number
};

const getRatingColor = (rating) => {
  switch (rating) {
    case 5:
      return "#76e23c";
    case 4:
      return "#a18cff";
    case 3:
      return "#f8d546";
    case 2:
      return "#72d8d0";
    case 1:
      return "#f2827f";
    default:
      return "#e0e0e0";
  }
};
const CourseReview = () => {
  const { slug } = useParams();

  // State to store slug, reviews, total reviews, and average rating
  const [storeSlug, setStoreSlug] = useState("");
  const [reviews, setReviews] = useState([]);
  const [reviewsAll, setAllReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSeeAll, setIsSeeAll] = useState(false);
  const [visibleReviews, setVisibleReviews] = useState(3);
  const [selectedRating, setSelectedRating] = useState(null);
  const [showFiltered, setShowFiltered] = useState(false);

  // Function to filter reviews based on the selected rating
  const filteredReviews = selectedRating
    ? reviews.filter((review) => parseInt(review.rating) === selectedRating)
    : reviews; // Show all reviews if no rating is selected

  // Function to show more reviews
  const showMoreReviews = () => {
    setVisibleReviews(reviewsAll.length); // Show all reviews when clicked
  };

  const handleRatingClick = (rating) => {
    if (selectedRating === rating) {
      // If the same rating is clicked again, toggle the filtered view off
      setShowFiltered(!showFiltered);
    } else {
      // If a new rating is clicked, show the filtered reviews
      setSelectedRating(rating);
      setShowFiltered(true);
    }
  };
  // Handler to toggle seeAllReviews view
  const handleToggleReviews = () => {
    setIsSeeAll((prev) => !prev);
  };

  const { isAppLoading } = useContext(LoadingContext);
  const { loadingCourseInformation, courseInformation } =
    useContext(CourseDetailsContext);

  const {
    data: courseReviewData,
    error: courseReviewError,
    loading: loadingCourseReview,
    getData: courseReviewPayload,
  } = useGetRequest(API_ENDPOINTS.COURSE_REVIEWS);

  // console.log("all review data ", reviews);
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
    }
  }, [slug]);

  useEffect(() => {
    const fetchCourseReviews = async () => {
      if (storeSlug) {
        try {
          await courseReviewPayload(storeSlug);
        } catch (err) {
          // console.log("Error fetching course reviews");
        }
      }
    };
    fetchCourseReviews();
  }, [storeSlug]);

  useEffect(() => {
    // Set a timeout to stop showing the skeleton after a while
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout duration as needed (e.g., 3 seconds)

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);

  useEffect(() => {
    if (!loadingCourseReview) {
      if (courseReviewError) {
        // console.log("Failed to fetch reviews and ratings");
      } else if (courseReviewData) {
        // Decrypt user information and calculate total reviews & average rating
        const decryptedReviews = courseReviewData.map((review) => ({
          ...review,
          User: {
            ...review.User,
            first_name: decryptData(review.User.first_name),
            last_name: decryptData(review.User.last_name),
          },
          // Calculate how long ago the review was added
          relativeTime: formatDistanceToNow(new Date(review.updated_on), {
            addSuffix: false,
          }),
        }));

        // Set total reviews count
        setTotalReviews(decryptedReviews.length);

        // Calculate the average rating
        const totalRating = decryptedReviews.reduce(
          (acc, review) => acc + parseFloat(review.rating),
          0
        );
        setAvgRating((totalRating / decryptedReviews.length).toFixed(1));

        // Sort by highest rating
        const sortedReviews = decryptedReviews.sort(
          (a, b) => parseFloat(b.rating) - parseFloat(a.rating)
        );
        const sortedAllReviews = decryptedReviews.sort(
          (a, b) => new Date(b.updated_on) - new Date(a.updated_on)
        );

        // Set all sorted reviews
        setReviews(sortedReviews);
        setAllReviews(sortedAllReviews);
      }
    }
  }, [courseReviewData, loadingCourseReview, courseReviewError]);

  if (isSeeAll) {
    return reviewsAll.length > 0 ? (
      <>
        <Box className="course-review-wrapper">
          <Box className="course-review-wrapper__header">
            <Box className="course-review-wrapper__header-rating">
              <Typography className="course-review-wrapper__header-rating--title">
                Reviews
              </Typography>
            </Box>
            <Button
              className="course-review-wrapper__header--button"
              variant="outlined"
              onClick={handleToggleReviews}
            >
              Hide
            </Button>
          </Box>
          <Box className="course-review-wrapper__header--subheading">
            <Grid container spacing={2} alignItems="center">
              {/* Total Reviews */}
              <Grid item xs={12} sm={4}>
                <Typography className="course-review-wrapper__header-rating--text">
                  Total Reviews
                </Typography>
                <Typography className="course-review-wrapper__header-rating--value">
                  {totalReviews}
                </Typography>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: "160px", marginX: 2 }}
              />
              {/* Average Rating */}
              <Grid item xs={12} sm={3}>
                <Typography className="course-review-wrapper__header-rating--text">
                  Average Rating
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography className="course-review-wrapper__header-rating--value">
                    {avgRating}
                  </Typography>
                  <Rating
                    value={avgRating}
                    readOnly
                    precision={0.5}
                    sx={{ marginLeft: "8px" }}
                  />
                </Box>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: "160px", marginX: 2 }}
              />
              {/* Ratings Distribution */}
              <Grid item xs={12} sm={4}>
                {[5, 4, 3, 2, 1].map((rating, index) => {
                  const ratingPercentage = calculateRatingPercentage(
                    rating,
                    reviews
                  ); // You can implement this function

                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={1}
                      key={index}
                      onClick={() => handleRatingClick(rating)} // Set the selected rating on click
                      style={{ cursor: "pointer" }} // Make it look clickable
                    >
                      <Rating
                        value={0}
                        readOnly
                        size="small"
                        max={1}
                        sx={{
                          marginRight: "8px",
                          "& .MuiRating-iconEmpty": {
                            color: "orange",
                          },
                        }}
                      />
                      <Typography variant="body2" sx={{ marginRight: "8px" }}>
                        {rating}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={ratingPercentage}
                        sx={{
                          width: "100%",
                          height: "10px",
                          borderRadius: "5px",
                          backgroundColor: "#e0e0e0",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: getRatingColor(rating), // You can implement a function to set different colors based on rating
                          },
                        }}
                      />
                      <Typography variant="body2" sx={{ marginLeft: "8px", textDecoration: "underline" }}>
                        {ratingPercentage}%
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
          <Box className="course-review-wrapper__scroll-wrapper">
            <Box className="course-review-wrapper__scroll-wrapper-content">
              {!showFiltered &&
                reviewsAll
                  .slice(0, visibleReviews)
                  .map((course, index) => (
                    <CourseReviewCard
                      key={index}
                      firstName={course.User.first_name}
                      lastName={course.User.last_name}
                      rating={course.rating}
                      imageUrl={course.User.profile_pic}
                      reviewText={course.review_text}
                      addedDate={course.relativeTime}
                    />
                  ))}

              {showFiltered &&
                filteredReviews.map((course, index) => (
                  <CourseReviewCard
                    key={index}
                    firstName={course.User.first_name}
                    lastName={course.User.last_name}
                    rating={course.rating}
                    imageUrl={course.User.profile_pic}
                    reviewText={course.review_text}
                    addedDate={course.relativeTime}
                  />
                ))}
            </Box>
          </Box>
          <Box className="course-review-wrapper__button-box">
            {visibleReviews < reviewsAll.length && (
              <Button
                fullWidth
                variant="outlined"
                className="course-review-wrapper__button-wrapper--buy-now"
                onClick={showMoreReviews}
              >
                Show more reviews
              </Button>
            )}
          </Box>
        </Box>
      </>
    ) : (
      ""
    );
  }

  return reviews.length > 0 ? (
    <>
      <Box className="course-review-wrapper">
        <Box className="course-review-wrapper__header">
          <Box className="course-review-wrapper__header-rating">
            <Box
              component="img"
              src={star}
              className="course-review-wrapper__header-rating--icon"
            />
            <Typography className="course-review-wrapper__header-rating--text">
              {avgRating} Course Rating - {totalReviews} Ratings
            </Typography>
          </Box>
          <Button
            className="course-review-wrapper__header--button"
            variant="outlined"
            onClick={handleToggleReviews}
          >
            See All
          </Button>
        </Box>
        <Box className="course-review-wrapper__scroll-wrapper">
          <Box className="course-review-wrapper__scroll-wrapper-content">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              freeMode={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false, // Continue autoplay after interaction
              }}
              modules={[FreeMode, Pagination, Autoplay]} // Include Autoplay module
              className="mySwiper"
            >
              {reviews.map((course, index) => (
                <SwiperSlide>
                  <CourseReviewCard
                    key={index}
                    firstName={course.User.first_name}
                    lastName={course.User.last_name}
                    rating={course.rating}
                    imageUrl={course.User.profile_pic}
                    reviewText={course.review_text}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Box>
    </>
  ) : loading ? (
    <SkeletonList />
  ) : (
    <Typography></Typography>
  );
};

export default CourseReview;
