import React, { useState, useEffect } from "react";
import { CourseInfo } from "components/common/courseInfo/CourseInfo";
import PaymentStatus from "components/module/app/purchaseHistory/PaymentStatus";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MUIProgressBar from "components/module/app/purchaseHistory/MUIProgressBar";
import download from "assets/icons/download-01.svg";
import { API_ENDPOINTS } from "config/ApiConfig";

export default function PurchaseHistoryTable({ groupedCourses, onStatusChange, statusFilter }) {
  const [alignment, setAlignment] = useState(statusFilter);

  useEffect(() => {
    setAlignment(statusFilter);
  }, [statusFilter]);

  const handleDownloadClick = (invoiceId) => {
    const url = `${API_ENDPOINTS.GET_INVOICE}/${invoiceId}`;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = invoiceId; // Use the invoiceId for the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setAlignment(newStatus);
      onStatusChange(newStatus); // Call the parent function
      // console.log("new status", newStatus);
    }
  };

  return (
    <>
      <ToggleButtonGroup
        className="toggle-button-group"
        value={alignment}
        exclusive
        onChange={handleStatusChange}
        aria-label="Status Filter"
      >
        <ToggleButton
          value="viewAll"
          className={alignment === "viewAll" ? "active" : "normal"}
        >
          View All
        </ToggleButton>
        <ToggleButton
          value="enrolled"
          className={alignment === "enrolled" ? "active" : "normal"}
        >
          Enrolled
        </ToggleButton>
        <ToggleButton
          value="completed"
          className={alignment === "completed" ? "active" : "normal"}
        >
          Completed
        </ToggleButton>
      </ToggleButtonGroup>

      <TableContainer className="purchase-history-table-container" component={Paper}>
        <Box className="purchase-history-table-container__rectangle-bar" />
        <Table sx={{ minWidth: 650 }} aria-label="purchase history table">
          <TableHead>
            <TableRow>
              <TableCell className="purchase-history-table__cell-title">No</TableCell>
              <TableCell className="purchase-history-table__cell-title">Status</TableCell>
              <TableCell className="purchase-history-table__cell-title" id="course" sx={{ padding: "16px 24px" }}>Course</TableCell>
              <TableCell className="purchase-history-table__cell-title" id="progress">Progress</TableCell>
              <TableCell className="purchase-history-table__cell-title">Invoice</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedCourses.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell className="purchase-history-table__cell-id">{index + 1}</TableCell>
                <TableCell className="purchase-history-table__cell-status">
                  <PaymentStatus status={course.status} />
                </TableCell>
                <TableCell className="purchase-history-table__cell-course">
                  <CourseInfo course={course} />
                </TableCell>
                <TableCell className="purchase-history-table__cell-progress">
                  <MUIProgressBar progress={course.progress} />
                </TableCell>
                <TableCell className="purchase-history-table__cell-invoice">
                  {course.showDownload && (
                    <Box className="purchase-history-table__cell-invoice-wrapper" onClick={() => handleDownloadClick(course.invoice)}>
                      <Box
                        component="img"
                        src={download}
                        className="purchase-history-table__cell-invoice-icon"
                      />
                      <Typography
                        variant="body2"
                        className="purchase-history-table__cell-invoice-text"
                      >
                        Download
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
