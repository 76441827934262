import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button, Paper, Avatar, Box } from '@mui/material';
import CertificateCourseCard from './courseCard';
import { decryptData } from 'utilityFunction/encryption&Decryption';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { API_ENDPOINTS } from 'config/ApiConfig';
import VideoPlayerSkeleton from 'components/common/skeleton/videoPlayerSkeleton';
import NofityUserContext from 'context/notifyUserContext/NotifyUserContext';
import { handleDownload } from 'utilityFunction/donwloadFile';
import { formatDuration } from 'utilityFunction/convertMinToHours';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url
).toString();

const CertificateVerificationCard = ({ data }) => {
    const [pdfUrl, setPdfUrl] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [image, setImage] = useState(true);
    const [profile, setProfile] = useState(true);
    const { notifyUser } = useContext(NofityUserContext);

    const courseDetails = {
        courseName: data.MasterCourse.course_name,
        image: image,
        slug: data.MasterCourse.slug,
    }

    const firstName = decryptData(data.User.first_name);
    const lastName = decryptData(data.User.last_name);
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();

    useEffect(() => {
        if (data && data.certificate_code) {
            const pdfFileName = data.certificate_code;
            setPdfUrl(`${API_ENDPOINTS.DOWNLOAD_CERTIFICATE}/${pdfFileName}.pdf`);
        }
        if (data && data.MasterCourse.MasterImage.name) {
            const imageFileName = data.MasterCourse.MasterImage.name;
            setImage(`${API_ENDPOINTS.GET_IMAGE}/${imageFileName}`);
        }
        if (data && data.User.profile_pic) {
            const profileFileName = data.User.profile_pic;
            setProfile(`${API_ENDPOINTS.PROFILE_PHOTO}/${profileFileName}`);
        }
    }, [data]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setIsLoading(false);
    };

    const handleShare = (url) => {
        if (navigator.share) {
            navigator.share({
                title: 'Check out this certificate',
                url: url,
            }).catch((error) => console.error('Error sharing:', error));
        } else {
            notifyUser("Failed to share",
                "Share functionality is not supported in this browser. Please copy the link to share.",
                "error"
            );

        }
    };
    return (
        <Box className="certificate-container-card">
            <Grid container spacing={4}>
                {/* Certificate Verification Header */}
                <Grid item xs={12}>
                    <Typography variant="h4" className="header-title">
                        Certificate Verification
                    </Typography>
                </Grid>

                {/* Main certificate preview and recipient details */}
                <Grid item xs={12} md={8} className='certificate-preview-card'>
                    {/* Certificate Preview */}
                    <Box className="certificate-preview" sx={{
                        overflow: 'hidden', // Ensures the border radius is applied to the content inside
                        maxWidth: '100%', // Adjust width if needed
                    }}>
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<VideoPlayerSkeleton />}
                        >
                            {!isLoading && numPages > 0 && (
                                <Page
                                    pageNumber={pageNumber}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false}
                                    width={400} // Adjust width if needed
                                />
                            )}
                        </Document>
                    </Box>

                    {/* Download and Share buttons */}
                    <Grid item xs={12} className="action-buttons">
                        <Button
                            className="navigate-chapter__button singleButton"
                            variant="outlined"
                            onClick={() => handleDownload(pdfUrl)}
                        >
                            Download
                        </Button>
                        <Button
                            variant="outlined"
                            className="navigate-chapter__button"
                            onClick={() => handleShare(pdfUrl)}
                        >
                            Share
                        </Button>
                    </Grid>

                    <Grid item xs={12} className='description'>
                        <Typography variant="body1" className="dummy-text">
                        This certificate verifies that <strong style={{fontWeight : 'bold'}}>{firstName} {lastName}</strong> successfully completed <b style={{fontWeight : 'bold'}}>{formatDuration(data.MasterCourse.duration)} Hours of Life Skills Course – {data.MasterCourse.course_name}</b> on <b style={{fontWeight : 'bold'}}>August 25, 2024,</b> as instructed by My True Skills. This certificate confirms that the entire course was completed as validated by the student. The course duration reflects the total instructional hours at the time of this completion.
                        </Typography>
                    </Grid>
                </Grid>

                {/* Recipient and course details */}
                <Grid item xs={12} md={4}>
                    <Box mb={2}>
                        <Typography variant="h6" className="section-title">
                            Certificate Recipient:
                        </Typography>
                        <Grid container spacing={2} alignItems="center" sx={{ marginTop: 1, marginBottom: 3 }}>
                            <Grid item>
                                <Avatar
                                    src={profile ? profile : null} 
                                    className="recipient-avatar"
                                >
                                    {!profile ? initials : null} // Display initials if no profile image
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className="recipient-name">
                                    {firstName} {lastName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>
                        <Typography variant="h6" className="section-title">
                            Course:
                        </Typography>
                        <Grid mt={2}>
                            <CertificateCourseCard courseDetails={courseDetails} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CertificateVerificationCard;
