import React, { useContext, useEffect, useState } from "react";
import { Container, Modal, Typography } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UserContext from "context/userContext/UserContext";
import { Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { API_ENDPOINTS } from "config/ApiConfig";
import { decryptData, encryptData } from "utilityFunction/encryption&Decryption";
import useGetRequest from "hooks/useGetRequest";
import usePostRequest from "hooks/usePostRequest";
import { Box } from "react-feather";
import LoadingContext from "context/loadingContext/LoadingContext";

const AuthLayout = () => {
  const { onLogin, userData } = useContext(UserContext);
  const [googleClientId, setGoogleClientId] = useState("");
  //app loading context
  const { isAppLoading, setIsAppLoading } = useContext(LoadingContext);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [countdown, setCountdown] = useState(6);
  // const {
  //   data: UserIdInfo,
  //   loading: loadingUserInfo,
  //   error: userIdError,
  //   getData: UserIdPayload,
  // } = useGetRequest(API_ENDPOINTS.GET_USER_INFO_BY_USER_ID);

  //send post req to the secret key api to retrieve the secret key required to decrypting the encrypted userInfo
  const {
    data: secretKeyData,
    error: secretKeyError,
    loading: loadingSecretKey,
    postData: secretPayload,
  } = usePostRequest(API_ENDPOINTS.SECRET_KEY);

  useEffect(() => {
    if (userData[0]) {
      setGoogleClientId(userData[0].GOOGLE_CLIENT_ID);
    }
  }, [userData]);

  //send the payload to the post req
  const fetchSecretKey = async () => {
    //set the global loading state to be true
    setIsAppLoading(true);
    try {
      await secretPayload({
        code: "connect_api_call",
      });
    } catch (error) {
      console.error("Error fetching secret key:", error);
    }
  };

  useEffect(() => {
    fetchSecretKey();
  }, [])

  // useEffect(() => {
  //   const checkTokenAndSetUserInfo = async () => {
  //     if (
  //       window.location.pathname === "/login" ||
  //       window.location.pathname === "/create-account" ||
  //       window.location.pathname === "/forgot-password" ||
  //       window.location.pathname.startsWith("/reset-password/")
  //     ) {
  //       return;
  //     }

  //     const storedToken = localStorage.getItem("token");
  //     if (!storedToken) {
  //       redirectToLogin();
  //       return;
  //     }

  //     try {
  //       const decodedToken = jwtDecode(storedToken);
  //       const currentTime = Math.floor(Date.now() / 1000);

  //       if (decodedToken.exp && decodedToken.exp < currentTime) {
  //         handleSessionExpiry();
  //       } else {
  //         axios.defaults.headers.common["Authorization"] = `Bearer ${storedToken.replace(/^["']|["']$/g, "")}`;
  //         await fetchUserIdInfo();
  //       }
  //     } catch (err) {
  //       redirectToLogin();
  //     }
  //   };

  //   const fetchUserIdInfo = async () => {
  //     try {
  //       await UserIdPayload();
  //     } catch (error) {
  //       redirectToLogin();
  //     }
  //   };

  //   const redirectToLogin = () => {
  //     setModalOpen(false);
  //     if (window.location.pathname !== "/login") {
  //       window.location.href = "/login";
  //     }
  //   };

  //   const handleSessionExpiry = () => {
  //     setModalOpen(true);
  //     localStorage.clear();
  //     let timer = setInterval(() => {
  //       setCountdown((prevCount) => {
  //         if (prevCount <= 1) {
  //           clearInterval(timer);
  //           redirectToLogin();
  //           return 0;
  //         }
  //         return prevCount - 1;
  //       });
  //     }, 1000);
  //   };

  //   checkTokenAndSetUserInfo();
  // }, []);

  return (
    <Container
      maxWidth={false}
      className="auth-layout__container auth-layout__container--login"
    >
      <GoogleOAuthProvider clientId={googleClientId}>
        <Outlet />
      </GoogleOAuthProvider>
      {/* Session Expiry Modal */}
      {/* <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <Box className="session-expiry-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Session Expired
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your session has expired. Redirecting to login page in {countdown}{" "}
            seconds
          </Typography>
        </Box>
      </Modal> */}
    </Container>
  );
};

export default AuthLayout;
