import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import mtsLogo from "assets/images/mts-logo.png";
import { Box, Button, Stack } from "@mui/material";
import Dropdown from "components/common/dropdownButton/DropdownButton";
import UserContext from "context/userContext/UserContext";
import NotificationWindow from "components/module/app/notification/Notification";

const Navbar = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext); // Access UserContext

  // Handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };

  // Conditionally render Navbar or Create Account Button
  return (
    <AppBar className="appbar-navbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="appbar-toolbar-navbar">
          <Link to="/app/dashboard">
            <Box
              component="img"
              src={mtsLogo}
              alt="MTS logo"
              className="navbar-logo"
            />
          </Link>
          {userData && userData.length > 0 ? (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              className="navbar-buttons"
            >
              <Button
                className="dashboard text"
                onClick={() => handleNavigation("dashboard")}
              >
                Dashboard
              </Button>
              <Button
                className="courses text"
                onClick={() => handleNavigation("my-course")}
              >
                Courses
              </Button>
              <NotificationWindow />
              <Dropdown />
            </Stack>
          ) : (
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              className="navbar-buttons"
            >
              <Button
                className="courses text"
                onClick={() => handleNavigation("courses")}
              >
                Courses
              </Button>
              <Button
                className="courses text"
                onClick={() => handleNavigation("login")}
              >
                Login
              </Button>
              <Button
                className="dropdown__button capitalize"
                onClick={() => handleNavigation("/create-account")}
              >
                Create Account
              </Button>
            </Stack>


          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
