import React from "react";
import { Box, Skeleton, List, ListItem, ListItemText } from "@mui/material";

export const SkeletonCard = () => (
  <Box
    sx={{
      width: "100%",
      maxWidth: 300,
      m: 2,
      p: 2,
      borderRadius: 2,
      boxShadow: 1,
    }}
  >
    <Skeleton
      variant="rectangular"
      width="100%"
      height={160}
      sx={{ borderRadius: 2, mb: 2 }}
    />
    <Skeleton variant="text" width="80%" height={24} sx={{ mb: 1 }} />
    <Skeleton variant="text" width="60%" height={20} sx={{ mb: 2 }} />
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
      <Skeleton variant="text" width="30%" />
    </Box>
    <Skeleton variant="text" width="100%" height={20} sx={{ mb: 1 }} />
    <Skeleton
      variant="rectangular"
      width="40%"
      height={36}
      sx={{ borderRadius: 1 }}
    />
  </Box>
);

// export const SkeletonLoader = () => (
//   <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
//     <Box
//       sx={{
//         display: "flex",
//         flexWrap: "nowrap",
//         justifyContent: "flex-start",
//         gap: 2,
//       }}
//     >
//       {[...Array(4)].map((_, index) => (
//         <SkeletonCard key={`row1-${index}`} />
//       ))}
//     </Box>
//     <Box
//       sx={{
//         display: "flex",
//         flexWrap: "nowrap",
//         justifyContent: "flex-start",
//         gap: 2,
//       }}
//     >
//       {[...Array(4)].map((_, index) => (
//         <SkeletonCard key={`row2-${index}`} />
//       ))}
//     </Box>
//   </Box>
// );

export const SkeletonLoader = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      // gap: 2,
    }}
  >
    {[...Array(2)].map((_, rowIndex) => (
      <Box
        key={`row-${rowIndex}`}
        sx={{
          display: "flex",
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
              },
          
          justifyContent: "flex-start",
          // gap: 2,
        }}
      >
        {[...Array(4)].map((_, cardIndex) => (
          <SkeletonCard
            key={`row${rowIndex}-${cardIndex}`}
            sx={{
              // Default for large screens: 4 cards per row
              flexBasis: "calc(25% - 8px)",
              "@media (max-width: 600px)": {
                // Mobile: 1 card per row
                flexBasis: "100%",
              },
              "@media (min-width: 600px) and (max-width: 960px)": {
                // Tablet: 2 cards per row
                flexBasis: "calc(50% - 8px)",
              },
  
            }}
          />
        ))}
      </Box>
    ))}
  </Box>
);

export const DropdownSkeleton = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      padding: 2,
      borderRadius: 2,
    }}
  >
    <Skeleton
      variant="circular"
      width={60}
      height={60}
      sx={{ bgcolor: "#E0E0E0" }}
    />
    <Box
      sx={{
        mt: 2,
        ml: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Skeleton variant="text" width={120} height={24} sx={{ mb: 0.5 }} />
      <Skeleton variant="text" width={80} height={16} />
    </Box>
  </Box>
);

export const SkeletonList = () => {
  return (
    <Box sx={{ bgcolor: "#fff", my: 5, borderRadius: "30px" }}>
      <List>
        {[1, 2, 3, 4].map((item) => (
          <ListItem key={item}>
            {/* <Skeleton variant="rectangular" width={40} height={40} /> */}
            <ListItemText
              primary={<Skeleton variant="text" width="80%" />}
              secondary={<Skeleton variant="text" width="60%" />}
              style={{ marginLeft: 16 }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SkeletonList;

export const SkeletonOverview = () => {
  return (
    <Box className="course-overview" id="overview">
      <Skeleton variant="text" width="30%" height={40} />
      <Skeleton variant="text" width="100%" height={20} />
      <Skeleton variant="text" width="100%" height={20} />
      <Skeleton variant="text" width="80%" height={20} />
    </Box>
  );
};

export const DashboardCardSkeleton = () => {
  return (
    <Box
      sx={{
        marginRight: 6,
        position: "absolute",
        top: "150px",
        right: "43px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
        zIndex: "100",
        padding: "8vh, 6vh, 8vh",
        borderRadius: 2,
        backgroundColor: "#f0f4f8",
        width: "35%",
        height: "70%",
      }}
    >
      {/* <Skeleton variant="text" width="60%" height={32} sx={{ mb: 1 }} /> */}

      {/* Icons and Meta Data */}
      {/* <Box display="flex" alignItems="center" gap={1} sx={{ mb: 2 }}>
        <Skeleton variant="circular" width={20} height={20} />
        <Skeleton variant="text" width={30} height={20} />
        <Skeleton variant="rectangular" width={20} height={20} />
        <Skeleton variant="text" width={30} height={20} />
        <Skeleton variant="rectangular" width={20} height={20} />
        <Skeleton variant="text" width={30} height={20} />
      </Box> */}

      {/* Description Skeleton */}
      {/* <Skeleton variant="text" width="100%" height={20} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="90%" height={20} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="85%" height={20} sx={{ mb: 3 }} /> */}

      {/* Inner Card Skeleton */}
      {/* <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: 2,
          padding: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 2,
        }}
      > */}
      {/* <Skeleton variant="rectangular" width={40} height={40} />
        <Box sx={{ flexGrow: 1 }}>
          <Skeleton variant="text" width="70%" height={20} />
          <Skeleton variant="text" width="40%" height={16} />
        </Box>
        <Skeleton variant="rectangular" width={20} height={20} />
      </Box> */}

      {/* Button Skeleton */}
      {/* <Skeleton
        variant="rectangular"
        width="30%"
        height={40}
        sx={{ mb: 2, ml: 2 }}
      /> */}

      {/* Footer Skeleton */}
      {/* <Box display="flex" justifyContent="space-between">
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Box> */}
    </Box>
  );
};
