import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import BasicBreadcrumbs from "components/common/breadcrumbs/BreakCrumbs";
import HelpCenterHeader from "components/module/app/helpCenter/helpCenterTwo/HelpCenterHeader";
import SettingUpAccount from "components/module/app/helpCenter/helpCenterTwo/SettingUpAccount";
import AccountSecurity from "components/module/app/helpCenter/helpCenterTwo/AccountSecurity";
import MtsSecurity from "components/module/app/helpCenter/helpCenterTwo/MtsSecurity";
import { Link, useParams } from "react-router-dom";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";

const HelpCenterPageTwo = () => {

  const {categoryId} = useParams();

  const [apiData, setApiData] = useState([]);

  const {
    data: helpCentre,
    loading: loadingHelpCentre,
    error: helpCentreError,
    getData: helpCentrePayLoad,
  } = useGetRequest(API_ENDPOINTS.HELP_CENTRE_SUB_CATEGORY);

  useEffect(() => {
    const fetchHelpCentre = async () => {
      if (!helpCentre) {
        await helpCentrePayLoad(categoryId);
      }
    };

    fetchHelpCentre();
  }, [helpCentre, helpCentrePayLoad]);

  useEffect(() => {
    if (!loadingHelpCentre && helpCentre) {
      setApiData(helpCentre);
    }
  }, [loadingHelpCentre, helpCentre]);

 // Ensure firstCategory is defined before accessing its properties
 const firstCategory = apiData.length > 0 ? apiData[0] : null;

  return (
    <Box className="help-center-page-two">
      <Breadcrumbs aria-label="breadcrumb">
      <Link to="/app/help-center-1" style={{ textDecoration: "none", color: "inherit" }}>
        Help Center
      </Link>

      {firstCategory ? (
        <Typography color="textPrimary">{firstCategory ? firstCategory.name : ""}</Typography>
      ) : (
        <Typography color="textPrimary">Current Page</Typography>
      )}
    </Breadcrumbs>
      <HelpCenterHeader
                title={firstCategory ? firstCategory.name : "Default Title"} 
                description={firstCategory ? firstCategory.description : "Default description."} 
                icon={firstCategory ? firstCategory.icon : ""} 
            />
      <Box className="help-center-page-two__wrapper">
      {firstCategory && firstCategory.subcategories ? (
          <SettingUpAccount
            data={firstCategory.subcategories}
            id={firstCategory.help_category_id}
          />
        ) : (
          <p>Loading subcategories...</p>
        )}
      
      </Box>
        {/* <AccountSecurity /> */}
      {/* <MtsSecurity /> */}
    </Box>
  );
};

export default HelpCenterPageTwo;
