import ResetPassword from "components/module/auth/ResetPassword";
import React from "react";

const ResetPasswordPage = () => {
  return (
    <>
      <ResetPassword />
    </>
  );
};

export default ResetPasswordPage;
