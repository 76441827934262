import React, { memo, useEffect, useState } from 'react';
import Countdown from 'react-countdown';

const QuizTimer = memo(({ estimatedCompletionTime, handleComplete }) => {
  // Convert minutes to milliseconds
  let timeInMilliseconds;

  // Check if estimatedCompletionTime is in seconds or minutes
  if (estimatedCompletionTime < 1) {
    timeInMilliseconds = estimatedCompletionTime * 1000;
  } else {
    timeInMilliseconds = estimatedCompletionTime * 60 * 1000;
  }

  // Renderer for the countdown
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // When the countdown completes, do something
      return <span>Time's up!</span>;
    } else {
      // Render the countdown
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds} remaining
        </span>
      );
    }
  };
  const [isClick, setClicked] = useState(false);

  useEffect(() => {
    if (isClick) {
      handleComplete();
    }
  }, [isClick]);

  const handleTimerComplete = () => {
    setClicked(true); // Calls the next function when timer ends
  };

  return (
    <div style={{ textAlign: 'right', justifyContent: 'flex-end', marginRight: '2rem' }}>
      <Countdown
        date={Date.now() + timeInMilliseconds}
        onComplete={handleTimerComplete}
        renderer={renderer}
      />
    </div>

  );
});

export default QuizTimer;
