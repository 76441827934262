import React from "react";
import { Chip, Box } from "@mui/material";
import check from "assets/icons/check.svg";
import creditcard from "assets/icons/credit-card-refresh.svg";
import xclose from "assets/icons/x-close.svg";

const PaymentStatus = ({ status }) => {
  let icon, className;

  if (status === "Paid") {
    icon = (
      <Box
        component="img"
        src={check}
        alt="Paid Icon"
        className="icon__check"
        id="paid"
      />
    );
    className = "purchase-state-chip paid";
  } else if (status === "Canceled") {
    icon = (
      <Box component="img" src={xclose} alt="Canceled Icon" className="icon" />
    );
    className = "purchase-state-chip canceled";
  } else if (status === "Refunded") {
    icon = (
      <Box
        component="img"
        src={creditcard}
        alt="Refunded Icon"
        className="icon"
      />
    );
    className = "purchase-state-chip refunded";
  }

  return (
    <Chip
      label={status}
      icon={icon}
      className={className}
      variant="filled"
      size="medium"
    />
  );
};

export default PaymentStatus;
