import { Box, Typography } from "@mui/material";
import React from "react";

const ActivityAnalyticsCard = ({ totalLesson, activitiesCompleted, duration }) => {
  return (
    <Box className="activity-card-wrapper">
      <Typography
        variant="h6"
        component="div"
        className="activity-card-wrapper__heading"
      >
        {activitiesCompleted}/{totalLesson}
      </Typography>
      <Typography
        variant="caption"
        component="div"
        className="activity-card-wrapper__caption"
      >
        Lesson Completed
      </Typography>
      <Typography
        variant="h6"
        component="div"
        className="activity-card-wrapper__heading"
      >
        {duration}
      </Typography>
      <Typography
        variant="caption"
        component="div"
        className="activity-card-wrapper__caption"
      >
        Spent Learning the Course
      </Typography>
    </Box>
  );
};

export default ActivityAnalyticsCard;
