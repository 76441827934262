import React from "react";
import ForgetPwd from "components/module/auth/ForgetPwd";
const ForgetPwdPage = () => {
  return (
    <>
      <ForgetPwd />
    </>
  );
};

export default ForgetPwdPage;
