import React from "react";
import { Header } from "components/common/header/Header";
import Profile from "components/module/app/settings/Profile";
import Security from "components/module/app/settings/Security";
import { Container, Grid } from "@mui/material";

const SettingPage = () => {
  return (
    <>
      <Header title="Settings" />
      <Container className="setting-page">
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Profile imageSize={90} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Security />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SettingPage;
