import { createBrowserRouter } from "react-router-dom";
import AppLayout from "components/layouts/appLayout/AppLayout";
import AuthLayout from "components/layouts/authLayout/AuthLayout";
import { authRoutes } from "routes/authRoute/authRoute";
import { appRoutes } from "routes/appRoute/appRoute";
import ErrorPage from "pages/errorPage/ErrorPage";
import { publicRoutes } from "./publicRoute/publicRoute";
import PublicLayout from "components/layouts/publicLayout/publicLayout";

const AdminRoutes = createBrowserRouter([
  {
    path: "/*",
    element: <AuthLayout />,
    children: [
      ...authRoutes,
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  {
    path: "app",
    element: <AppLayout />,
    children: [
      ...appRoutes,
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <PublicLayout />,
    children: [
      ...publicRoutes,
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

export default AdminRoutes;
