
import VerifyComponent from "components/module/public/certificate/verify-certificate";
import React from "react";

const VerifyCertificatePage = () => {


    return (
        <>
        <VerifyComponent />
          
        </>);
};

export default VerifyCertificatePage;
