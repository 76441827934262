export const convertMinToHour = (minutes) => {
  const hour = minutes / 60;
  // rounds to 1 decimal places
  return `${hour.toFixed(0)} Hrs`;
};

export const convertMinToHourAndMin = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
};

export const formatDuration = (minutes) => {
  if (minutes < 60) {
    // If less than 60 minutes, return in minutes
    return `${minutes} min`;
  } else if (minutes % 60 === 0) {
    // If an exact number of hours, return in hours
    const hours = minutes / 60;
    return `${hours} Hr${hours > 1 ? "s" : ""}`;
  } else {
    // If hours and minutes, return both
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} Hr${hours > 1 ? "s" : ""} ${remainingMinutes} min`;
  }
};
