import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserProvider } from "context/userContext/UserContext";
import { AppProvider } from "context/appContext/AppContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NofityUserProvider } from "context/notifyUserContext/NotifyUserContext";
import { LoadingProvider } from "context/loadingContext/LoadingContext";
import { CourseDetailsProvider } from "context/courseDetailsContext/CourseDetailsContext";
import { LessonProvider } from "context/lessonContext/LessonContext";
import { CourseViewProvider } from "context/courseViewContext/CourseViewContext";
import { SocketProvider } from "components/module/app/chatRoom/SocketContext";
import * as Sentry from "@sentry/react"; 
import ReactGA from "react-ga4"; 
Sentry.init({ dsn: "https://86644f54bf3ed17bc768957b32495ea1@o4508267622957056.ingest.us.sentry.io/4508267721261056", integrations: [], });
ReactGA.initialize("G-1NGW6SJR33");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadingProvider>
    <UserProvider>
      <NofityUserProvider>
        <CourseDetailsProvider>
          <CourseViewProvider>
            <LessonProvider>
              <AppProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <SocketProvider>
                  <App />
                  </SocketProvider>
                </LocalizationProvider>
              </AppProvider>
            </LessonProvider>
          </CourseViewProvider>
        </CourseDetailsProvider>
      </NofityUserProvider>
    </UserProvider>
  </LoadingProvider>
);
