import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingContext from "context/loadingContext/LoadingContext";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import SkeletonList from "components/common/skeleton/SkeletonCard";

const FAQCard = () => {
  //store the slug in a var
  const { slug } = useParams();
  //state to store the slug
  const [storeSlug, setStoreSlug] = useState("");

  // state to save the expanded accordian
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);

  //store the course chapters details
  const [courseChapterFAQ, setCourseChapterFAQ] = useState([]);
  //loading global context
  const { isAppLoading } = useContext(LoadingContext);

  //store the slub in a usestate
  useEffect(() => {
    if (slug) {
      setStoreSlug(slug);
    }
  }, [slug]);

  //trigger the get req for the chapters timeline
  const {
    data: chapterFAQData,
    error: chapterFAQError,
    loading: loadingChapterFAQ,
    getData: chapterFAQPayload,
  } = useGetRequest(API_ENDPOINTS.COURSE_FAQ);

  useEffect(() => {
    const fetchChapterFAQ = async () => {
      if (storeSlug) {
        try {
          await chapterFAQPayload(storeSlug);
        } catch (err) {
          // console.log("error with the get request api call");
        }
      }
    };
    fetchChapterFAQ();
  }, [storeSlug]);

  useEffect(() => {
    if (!loadingChapterFAQ) {
      if (chapterFAQError) {
        // console.log("error fetching the chapter error:", chapterFAQError);
      } else if (chapterFAQData) {
        //setting the data from the get api to the local state
        setCourseChapterFAQ(chapterFAQData);
      }
    }
  }, [chapterFAQData, chapterFAQData, chapterFAQError]);

  useEffect(() => {
    // Set a timeout to stop showing the skeleton after a while
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the timeout duration as needed (e.g., 3 seconds)

    return () => clearTimeout(timer); // Clean up the timeout
  }, []);

  // console.log("course chapter data in local state:", courseChapterFAQ);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return courseChapterFAQ.length > 0 ? (
    <Box className="faq-wrapper">
      <Typography variant="h5" className="faq-wrapper__title">
        Frequently Asked Questions
      </Typography>
      {courseChapterFAQ.map((faq, index) => (
        <Accordion
          className={`faq-wrapper__accordion ${
            expanded === `panel${index}` ? "expanded" : "collapsed"
          }`}
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            className="faq-wrapper__accordion--heading"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {faq.MasterFaq.question}
          </AccordionSummary>
          <AccordionDetails className="faq-wrapper__accordion--description">
            {faq.MasterFaq.answer}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  ) : loading ? (
    <SkeletonList />
  ) : (
    <Typography></Typography>
  );
};

export default FAQCard;
