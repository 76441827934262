import React from "react";
import { Box, Typography } from "@mui/material";
import lesson from "assets/icons/lesson.svg";
import barchart from "assets/icons/bar-chart.svg";
import clock from "assets/icons/clock2.svg";
import { formatDuration } from "utilityFunction/convertMinToHours";

const CourseDetails = ({ course_name, description, difficulty, duration, lessonCount }) => {
  return (
    <Box className="course-details-wrapper">
      <Box className="course-details-wrapper__description">
        <Typography className="course-details-wrapper__description--title">
          {course_name}
        </Typography>
        <Typography className="course-details-wrapper__description--caption">
          {description}
        </Typography>
      </Box>
      <Typography className="course-details-wrapper__heading">
        Course
      </Typography>
      <Box className="course-details-wrapper__cards-wrapper">
        <Box className="course-details-wrapper__cards-wrapper--card">
          <Typography className="course-details-wrapper__cards-wrapper--card--title">
            Lessons
          </Typography>
          <Box className="course-details-wrapper__cards-wrapper--card-content">
            <Box
              className="course-details-wrapper__cards-wrapper--card-content--icon"
              component="img"
              alt="lesson"
              src={lesson}
            />
            <Typography className="course-details-wrapper__cards-wrapper--card-content--caption">
              {lessonCount}
            </Typography>
          </Box>
        </Box>
        <Box className="course-details-wrapper__cards-wrapper--card">
          <Typography className="course-details-wrapper__cards-wrapper--card--title">
            Duration
          </Typography>
          <Box className="course-details-wrapper__cards-wrapper--card-content">
            <Box
              className="course-details-wrapper__cards-wrapper--card-content--icon"
              component="img"
              alt="duration"
              id="duration"
              src={clock}
            />
            <Typography className="course-details-wrapper__cards-wrapper--card-content--caption">
              {formatDuration(duration)}
            </Typography>
          </Box>
        </Box>
        <Box className="course-details-wrapper__cards-wrapper--card">
          <Typography className="course-details-wrapper__cards-wrapper--card--title">
            Difficulty Level
          </Typography>
          <Box className="course-details-wrapper__cards-wrapper--card-content">
            <Box
              className="course-details-wrapper__cards-wrapper--card-content--icon"
              component="img"
              alt="progress"
              id="progress"
              src={barchart}
            />
            <Typography className="course-details-wrapper__cards-wrapper--card-content--caption">
              {difficulty}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseDetails;
