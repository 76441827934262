
import ChatRoomPage from "pages/appPage/chatRoomPage/ChatRoomPage";
import CourseDetailsPage from "pages/appPage/courseDetailsPage/CourseDetailsPage";
import CourseLessonPage from "pages/appPage/courseLessonPage/CourseLessonPage";
import MCQLessonPage from "pages/appPage/courseLessonPage/MCQLessonPage";
import CourseViewPage from "pages/appPage/courseViewPage/CourseViewPage";
import DashboardPage from "pages/appPage/dashboardPage/DashboardPage";
import HelpCenterPageOne from "pages/appPage/helpCenterPage/HelpCenterPageOne";
import HelpCenterPageThree from "pages/appPage/helpCenterPage/HelpCenterPageThree";
import HelpCenterPageTwo from "pages/appPage/helpCenterPage/HelpCenterPageTwo";
import MyCoursePage from "pages/appPage/myCoursePage/MyCoursePage";
import PaymentFailurePage from "pages/appPage/paymentPages/PaymentFailurePage";
import PaymentSuccessPage from "pages/appPage/paymentPages/PaymentSuccessPage";
import PurchaseHistoryPage from "pages/appPage/purchaseHistoryPage/PurchaseHistoryPage";
import ReportContentPage from "pages/appPage/reportContentPage/ReportContentPage";
import SettingPage from "pages/appPage/settingsPage/SettingPage";
import GetLivepaymentChart from "pages/publicpage/payment/Payment";
// import DummyComponent from "test/DummyComponent";

export const appRoutes = [
  {
    path: "",
    element: <DashboardPage />,
    index: true,
  },
  {
    path: "dashboard",
    element: <DashboardPage />,
  },

  {
    path: "my-course",
    element: <MyCoursePage />,
  },
  {
    path: "purchase-history",
    element: <PurchaseHistoryPage />,
  },
  {
    path: "settings",
    element: <SettingPage />,
  },
  {
    path: "course/:slug/learn",
    element: <CourseViewPage />,
  },
  {
    path: "report-content",
    element: <ReportContentPage />,
  },
 
  {
    path: "help-center-1",
    element: <HelpCenterPageOne />,
  },
  {
    path: "help-center-2/:categoryId",
    element: <HelpCenterPageTwo />,
  },
  {
    path: "help-center-3/:categoryId/view/:subCategoryId",
    element: <HelpCenterPageThree />,
  },

  {
    path: "course/:slug",
    element: <CourseDetailsPage />,
  },
  // {
  //   path: "test",
  //   element: <DummyComponent />,
  // },
  {
    path: "course/:chapter/learn/:lesson",
    element: <CourseLessonPage />,
  },
  {
    path: "payment/success/:paymentId",
    element: <PaymentSuccessPage />,
  },
  {
    path: "payment/failure/:paymentId",
    element: <PaymentFailurePage />,
  },
  {
    path: "course/:chapter/learn/:lesson/mcq",
    element: <MCQLessonPage />,
  },
  {
    path: "chat/:slug",
    element: <ChatRoomPage />,
  },
  {
    path: "course/:slug/purchase/:username",
    element: <GetLivepaymentChart />,
  },
];
