import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Grid,
  AppBar,
  Toolbar,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { Delete, MoreVert, Edit } from "@mui/icons-material";
import MessageInput from "./MessageInput";
import { io } from "socket.io-client";
import axios from "axios";
import UserContext from "context/userContext/UserContext";
import { useParams } from "react-router-dom";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import { decryptData } from "utilityFunction/encryption&Decryption";
import usePostRequest from "hooks/usePostRequest";

const socket = io(API_ENDPOINTS.SOCKET_URL);

function ChatArea() {
  const [messages, setMessages] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const { userData } = useContext(UserContext);
  const { slug } = useParams();
  const [apiLoading, setApiLoading] = useState(false);
  const [channels, setChannels] = useState(null);
  const [messageCount, setMessageCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [messages, shouldScrollToBottom]);

  const {
    data: channelsData,
    loading: loadingChannelsData,
    getData: fetchChannelsData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS);
  const {
    data: groupData,
    loading: loadingGroupData,
    getData: fetchGroupData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS_MESSAGE);
  const {
    data: groupCountData,
    loading: loadingGroupCountData,
    getData: fetchGroupCountData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS_MESSAGE_COUNT);
  const {
    data: recentData,
    loading: loadingRecentData,
    getData: fetchRecentData,
  } = useGetRequest(API_ENDPOINTS.GET_CHAT_ROOM_CHANNELS_RECENT_MESSAGE);
  // const {
  //   data: deleteData,
  //   loading: loadingDeleteData,
  //   postData: fetchDeleteData,
  // } = usePostRequest(API_ENDPOINTS.DELETE_CHAT_ROOM_MESSAGE);
  // const {
  //   data: editData,
  //   loading: loadingEditData,
  //   postData: fetchEditData,
  // } = usePostRequest(API_ENDPOINTS.UPDATE_CHAT_ROOM_MESSAGE);

  useEffect(() => {
    if (!apiLoading) {
      fetchChannels();
      setApiLoading(true);
    }
  }, [apiLoading]);
  useEffect(() => {
    if (groupCountData && !loadingGroupCountData) {
      setMessageCount(groupCountData.messageCount);
      // console.log("groupCountData -> ", messageCount);
    }
  }, [groupCountData, loadingGroupCountData]);

  useEffect(() => {
    if (channelsData && !loadingChannelsData) {
      setChannels(channelsData);
      // console.log("channelsData -> ", channelsData);
    }
  }, [channelsData, loadingChannelsData]);

  useEffect(() => {
    if (groupData && !loadingGroupData) {
      // console.log("groupData -> ", groupData);
      
      const groupedMessages = groupData.messageData.map((msg) => {
        const attachments = groupData.attachmentsData.filter(
          (attachment) => attachment.message_id === msg.ch_message_id
        );
        return {
          ...msg,
          attachments,
        };
      });
  
      // Prepend new messages to the existing messages
      setMessages((prevMessages) => [...groupedMessages, ...prevMessages]);
    }
  }, [groupData, loadingGroupData]);
  

  // useEffect(() => {
  //   if (messages && messages.length > 0) {
  //     console.log("messages -> ", messages);
  //   }
  // }, [messages]);

  // useEffect(() => {
  //   if (offset ) {
  //     console.log("offset -> ", offset);
  //   }
  // }, [offset]);

  const fetchChannels = async () => {
    try {
      await fetchChannelsData(slug);
    } catch (error) {
      console.error("Failed to fetch channels:", error);
    }
  };

  const handleChannelClick = async (channel) => {
    setOffset(0);
    setMessages([]);
    setSelectedChannel(channel);
    await fetchMessagesCount(channel.chatroom_id);
    fetchMessages(channel.chatroom_id, limit, 0);
  };

  const handleLoadMore = () => {
    setShouldScrollToBottom(false);
    if (!isFetching) {
      fetchMessages(selectedChannel?.chatroom_id, limit, offset); // Pass the current offset state
    }
    if (offset >= messageCount) {
      setHasMore(false);
    }
  };

  const fetchMessages = async (channelId, limit, currentOffset) => {
    try {
      setIsFetching(true);

      // Calculate the next offset
      const nextOffset = currentOffset + limit;

      const apiUrl = `${channelId}?limit=${limit}&offset=${currentOffset}`;
      await fetchGroupData(apiUrl); 
      
      setOffset(nextOffset); 

      // console.log("old offset value 0:", nextOffset);
      // console.log("New offset value 1 :", offset);
      setIsFetching(false);
    } catch (error) {
      console.error("Error fetching messages:", error);
      setIsFetching(false);
    }
  };
  

  const fetchMessagesCount = async (channelId) => {
    try {
      await fetchGroupCountData(channelId);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Handle receiving new messages via socket
  useEffect(() => {
    socket.on("receiveMessage", async (newMessage) => {
      if (newMessage.chatroom_id === selectedChannel?.chatroom_id) {
        try {
          await fetchRecentData(newMessage.ch_message_id);
        } catch (error) {
          console.error("Error fetching message details:", error);
        }
      }
    });
    return () => {
      socket.off("receiveMessage");
    };
  }, [selectedChannel, fetchRecentData]);

  // Listen for message deletion event
  useEffect(() => {
    socket.on("messageDeleted", (deletedMessage) => {
      if (deletedMessage.chatroom_id === selectedChannel?.chatroom_id) {
        setMessages((prevMessages) =>
          prevMessages.filter(
            (msg) => msg.ch_message_id !== deletedMessage.ch_message_id
          )
        );
      }
    });
    return () => {
      socket.off("messageDeleted");
    };
  }, [selectedChannel]);

  // Listen for message edit event
  useEffect(() => {
    socket.on('messageEdited', (editedMessage) => {
      if (editedMessage.chatroom_id === selectedChannel?.chatroom_id) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.ch_message_id === editedMessage.ch_message_id
              ? { ...msg, content: editedMessage.content, is_edited: true }
              : msg
          )
        );
      }
    });
    return () => {
      socket.off('messageEdited');
    };
  }, [selectedChannel]);


  // Handle adding recent message to state
  useEffect(() => {
    if (!loadingRecentData && recentData) {
      const processedMessage = {
        ...recentData.messageData,
        attachments: recentData.attachmentsData,
      };
      setMessages((prevMessages) => [...prevMessages, processedMessage]);
    }
  }, [loadingRecentData, recentData]);

  // Handle delete functionality
  const handleDeleteMessage = (message_id) => {
    socket.emit(
      "deleteMessage",
      { ch_message_id: message_id, chatroom_id: selectedChannel?.chatroom_id },
      (response) => {
        if (response === "Message deleted") {
          console.log("Message successfully deleted on server");
        } else {
          console.error("Failed to delete message:", response);
        }
      }
    );
  };

  // State and handlers for the menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  // State for editing
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editText, setEditText] = useState("");
  const boxRef = useRef(null);

  const handleMenuClick = (event, messageId) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessageId(messageId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMessageId(null);
  };

  const handleEditClick = (message) => {
    setEditingMessageId(message.ch_message_id);
    setEditText(message.content);
    handleMenuClose();
  };

  const handleEditChange = (e) => {
    setEditText(e.target.value);
  };

  const handleEditSubmit = async (messageId) => {
    socket.emit(
      "editMessage",
      { ch_message_id: messageId, chatroom_id: selectedChannel?.chatroom_id, content: editText },
      (response) => {
        if (response === "Message deleted") {
          console.log("Message successfully deleted on server");
        } else {
          console.error("Failed to delete message:", response);
        }
      }
    );
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.ch_message_id === messageId ? { ...msg, content: editText } : msg
      )
    );
    setEditingMessageId(null);
    setEditText("");
  };

  const handleEditCancel = () => {
    setEditingMessageId(null);
    setEditText("");
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setEditingMessageId(null);
      setEditText("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Box sx={{ width: 250, bgcolor: "primary.main", color: "white" }}>
        <Typography variant="h6" sx={{ m: 2 }}>
          Channels
        </Typography>
        <List>
          {channels &&
            channels.map((channel) => (
              <ListItem
                button
                key={channel.chatroom_id}
                onClick={() => handleChannelClick(channel)}
              >
                <ListItemText primary={`# ${channel.name}`} />
              </ListItem>
            ))}
        </List>
        <Divider sx={{ bgcolor: "white" }} />
      </Box>

      <Grid container direction="column" sx={{ flex: 1 }}>
        <AppBar position="static" sx={{ bgcolor: "primary.dark" }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {selectedChannel
                ? `# ${selectedChannel.name}`
                : "Select a Channel"}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            padding: 2,
          }}
        >
                <List>
        {hasMore && (
          <button onClick={handleLoadMore} disabled={loading}>
            {loading ? "Loading..." : "Load More Messages"}
          </button>
        )}
      </List>

          <List sx={{ flex: 1 }}>
            {messages && Array.isArray(messages) && messages.length > 0 ? (
              messages.map((msg) => (
                <ListItem
                  key={msg.ch_message_id}
                  alignItems="flex-start"
                  sx={{
                    display: "flex",
                    justifyContent:
                      msg.sender === userData[0].user_id
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      padding: 1,
                      maxWidth: "60%",
                      bgcolor:
                        msg.sender === userData[0].user_id
                          ? "primary.light"
                          : "grey.300",
                      color:
                        msg.sender === userData[0].user_id ? "white" : "black",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ marginRight: "20px" }}
                    >
                      {msg.User
                        ? `${decryptData(msg.User.first_name)} ${decryptData(
                          msg.User.last_name
                        )}`
                        : "Unknown User"}
                    </Typography>

                    {/* Render editable TextField if this message is being edited */}
                    {editingMessageId === msg.ch_message_id ? (
                      <Box ref={boxRef} sx={{ mt: 1 }}>
                        <TextField
                          value={editText}
                          onChange={handleEditChange}
                          onBlur={() => handleEditSubmit(msg.ch_message_id)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleEditSubmit(msg.ch_message_id);
                            } else if (e.key === "Escape") {
                              handleEditCancel();
                            }
                          }}
                          size="small"
                          fullWidth
                          autoFocus
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mt: 0.5,
                          }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => handleEditCancel()}
                          >
                            <Typography variant="caption" color="error">
                              Cancel
                            </Typography>
                          </IconButton>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        {msg.message_type === "Text" && (
                          <Typography
                            variant="body2"
                            sx={{ marginRight: "20px" }}
                          >
                            {msg.content}
                          </Typography>
                        )}
                        {msg.message_type === "Image" &&
                          msg.attachments &&
                          msg.attachments.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body2">
                                {msg.content}
                              </Typography>
                              <img
                                src={`${API_ENDPOINTS.GET_CHAT_ROOM_FILE}/${msg.attachments[0].file_name}`}
                                alt={msg.attachments[0].file_name}
                                style={{ maxWidth: "100%" }}
                              />
                            </Box>
                          )}
                        {msg.message_type === "File" &&
                          msg.attachments &&
                          msg.attachments.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body2">
                                {msg.content}
                              </Typography>
                              <a
                                href={`${API_ENDPOINTS.GET_CHAT_ROOM_FILE}/${msg.attachments[0].file_name}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View PDF
                              </a>
                            </Box>
                          )}
                      </>
                    )}

                    {msg.sender === userData[0].user_id &&
                      editingMessageId !== msg.ch_message_id && (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="options"
                            onClick={(event) =>
                              handleMenuClick(event, msg.ch_message_id)
                            }
                            sx={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              marginLeft: "10px",
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={
                              Boolean(anchorEl) &&
                              selectedMessageId === msg.ch_message_id
                            }
                            onClose={handleMenuClose}
                            sx={{
                              "& .MuiPaper-root": {
                                minWidth: 120, // Reducing the width of the menu
                                padding: 0.5,
                                outline: "none",
                                boxShadow: "none",
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => handleEditClick(msg)}
                              sx={{
                                fontSize: "0.875rem",
                                padding: "4px 8px",
                              }}
                            >
                              <Edit
                                fontSize="small"
                                sx={{ marginRight: 0.5 }}
                              />{" "}
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleDeleteMessage(msg.ch_message_id);
                                handleMenuClose();
                              }}
                              sx={{
                                fontSize: "0.875rem", // Reducing font size for smaller text
                                padding: "4px 8px",
                              }}
                            >
                              <Delete
                                fontSize="small"
                                sx={{ marginRight: 0.5 }}
                              />{" "}
                              Delete
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                  </Paper>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText
                  primary={<Typography>No messages available.</Typography>}
                />
              </ListItem>
            )}
            <div ref={messagesEndRef}></div>
          </List>
          <Divider />
          {selectedChannel && (
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                width: "100%",
                bgcolor: "white",
                zIndex: 999,
              }}
            >
              <MessageInput
                user_id={userData[0].user_id}
                chatroom_id={selectedChannel?.chatroom_id}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default ChatArea;
