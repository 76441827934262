import React, { createContext, useState } from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const LoadingContext = createContext();

export default LoadingContext;

export const LoadingProvider = ({ children }) => {
  const [isAppLoading, setIsAppLoading] = useState(true);

  return (
    <LoadingContext.Provider value={{ isAppLoading, setIsAppLoading }}>
      {children}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isAppLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </LoadingContext.Provider>
  );
};
