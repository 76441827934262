export const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = url.split('/').pop(); // Set the download file name
      link.click();
      URL.revokeObjectURL(link.href); // Clean up after download
    } catch (error) {
      console.error('Download failed:', error);
    }
  };