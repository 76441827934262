import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import paymentSuccess from "assets/icons/payment-success.svg";
import { useNavigate, useParams } from "react-router-dom";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { handleDownload } from "utilityFunction/donwloadFile";

const PaymentSuccessPage = () => {
  const [timer, setTimer] = useState(9);
  const [paymentID, setPaymentID] = useState("");
  const navigate = useNavigate();

  //state to store the payment info
  const [paymentInfo, setPaymentInfo] = useState({});
  const [invoiceId, setInvoiceId] = useState(null);

  const { paymentId } = useParams();

  const {
    data: paymentData,
    error: paymentError,
    loading: loadingPayment,
    getData: paymentPayload,
  } = useGetRequest(API_ENDPOINTS.PAYMENT_STATUS);

  //useeffect to store the slug in the local storage
  useEffect(() => {
    if (paymentId) {
      setPaymentID(paymentId);
    }
  }, [paymentId]);

  //trigger the api request on page reload
  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (paymentID) {
        try {
          await paymentPayload(paymentID);
        } catch (err) {
          console.log(
            "error send the get requesrt to fetch the payment status"
          );
        }
      }
    };
    fetchPaymentStatus();
  }, [paymentID]);

  //useeffect to store the data from the get req to the local state
  useEffect(() => {
    if (!loadingPayment && paymentData) {
      if (paymentError) {
        console.log("error with the payment", paymentError);
      } else {
        setPaymentInfo(paymentData.paymentDetails);
        const ReceiptId = paymentData.paymentDetails.invoice_id;
        setInvoiceId(`${API_ENDPOINTS.GET_INVOICE}/${ReceiptId}.pdf`);
      }
    }
  }, [paymentData, paymentError, loadingPayment]);

  useEffect(() => {
    //check if the paymentInfo object has any properties.
    if (Object.keys(paymentInfo).length > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            navigate(`/app/course/${paymentInfo.MasterCourse.slug}/learn`);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [paymentInfo]);

  return (
    <Box className="payment">
      <Box
        component="img"
        src={paymentSuccess}
        alt="payment success"
        className="payment__image"
      />
      <Typography className="payment__title">Payment Successful!</Typography>
      <Box className="payment__description-container">
        <Typography className="payment__description">
          Thank you for you payment.
        </Typography>
        <Typography className="payment__description">
          A confirmation email along with Invoice will be sent your registered
          email
        </Typography>
      </Box>
      <Button variant="outlined" className="payment__button" onClick={() => handleDownload(invoiceId)}>
        Download Receipt
      </Button>
      <Typography className="payment__redirect-text">
        Page will be automatically redirected to the course dasboard page in{" "}
        {timer} {""}Seconds
      </Typography>
    </Box>
  );
};

export default PaymentSuccessPage;
