import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import yellowclock from "assets/icons/clock.svg";
import chevronright from "assets/icons/chevron-right.svg";
import book from "assets/icons/book.svg";
import { formatDuration } from "utilityFunction/convertMinToHours";

const DisciplineCard = ({ lessonData }) => {
  const navigate = useNavigate();
  const [lessonInfo, setLessonInfo] = useState({});
  // const [lessonURL, setLessonURL] = useState("");

  useEffect(() => {
    if (lessonData) {
      setLessonInfo(lessonData);
    }
  }, [lessonData]);

  // console.log("lessonInfo", lessonInfo);
  const navigateToLesson = () => {
    if (lessonInfo) {
      navigate(
        `/app/course/${lessonInfo.MasterCourse.slug}/learn/${lessonInfo.MasterLesson.lesson_slug}`
      );
    }
  };
  return (
    lessonInfo && (
      <Card className="dashboard-card-content__download">
        <CardContent className="dashboard-card-content__download-content">
          <Box className="dashboard-card-content__download-image-border">
            <Box component="img" src={book} />
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="subtitle1"
              component="div"
              className="dashboard-card-content__download-content-main-text"
            >
              {lessonInfo?.MasterLesson?.lesson_name || ""}
            </Typography>
            <Box className="dashboard-card-content__download-content-text-wrapper">
              <Box
                component="img"
                src={yellowclock}
                className="dashboard-card-content__download-content-yellow-clock"
              />
              <Typography
                variant="body2"
                className="dashboard-card-content__download-content-text"
              >
                {formatDuration(
                  lessonInfo?.MasterLesson?.estimated_completion_time
                ) || ""}
              </Typography>
            </Box>
          </Box>

          <IconButton
            edge="end"
            sx={{ p: 0 }}
            className="dashboard-card-content__download-content--iconButton"
          >
            <Box
              onClick={navigateToLesson}
              component="img"
              src={chevronright}
              className="dashboard-card-content__download-content-chevron-right"
            />
          </IconButton>
        </CardContent>
      </Card>
    )
  );
};

export default DisciplineCard;
