import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import messageQuestion from "assets/icons/message-question-circle (1).svg";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import { useNavigate } from "react-router-dom"; // Updated import

export const SearchBarCard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const {
    data: getQuestion,
    error: getQuestionError,
    loading: loadingGetQuestion,
    postData: postGetQuestion,
  } = usePostRequest(API_ENDPOINTS.FIND_HELP_CENTRE_QUESTION);

  const navigate = useNavigate(); // Initialize useNavigate

  // Trigger API call when searchTerm changes and is not empty
  useEffect(() => {
    if (searchTerm.trim() !== "") {
      const timer = setTimeout(() => {
        postGetQuestion({ query: searchTerm });
      }, 300); // Debouncing the API call

      return () => clearTimeout(timer); // Cleanup function for debouncing
    }
  }, [searchTerm]); // Only depends on searchTerm

  // Handle input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setShowDropdown(true);
  };

  // Handle selection of a dropdown item
  const handleSelectItem = (item) => {
    setSearchTerm(item.question); // Fill the input with the selected item's question
    setShowDropdown(false); // Close the dropdown
    navigate(`/app/help-center-3/${item.HelpCategory.help_category_id}/view/${item.help_question_id}`); // Redirect to the question page
  };

  return (
    <div className="search-bar-wrapper">
    <Card className="search-bar-card-wrapper">
      <Typography variant="h5" className="search-bar-card-wrapper__title">
        How can we help?
      </Typography>

      <Box component="img" src={messageQuestion} alt="message" className="search-bar-card-wrapper__image" />

      <TextField
        className="search-bar-card-wrapper__search-bar"
        size="medium"
        id="outlined-basic"
        placeholder="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setShowDropdown(true)}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none', 
            },
            '&:hover fieldset': {
              border: 'none', 
            },
            '&.Mui-focused fieldset': {
              border: 'none', 
            },
          },
        }}
      />
         </Card>

      {/* Show loading spinner while fetching */}
      {/* {loadingGetQuestion && <CircularProgress size={24} />} */}

      {/* Dropdown with search results */}
      {showDropdown && searchTerm && (
        <List className="search-bar-dropdown">
          {getQuestion?.map((item) => (
            <ListItem button key={item.help_question_id} onClick={() => handleSelectItem(item)}>
              <ListItemText primary={item.question} />
            </ListItem>
          ))}
          {!getQuestion?.length && <MenuItem>No results found</MenuItem>}
        </List>
      )}

      {/* Handle errors */}
      {getQuestionError && <Typography color="error">Error fetching questions.</Typography>}

    </div>
  );
};
