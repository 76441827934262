import React from "react";
import { Box, Typography } from "@mui/material";
import barchart from "assets/icons/bar-chart.svg";
import clock from "assets/icons/clock2.svg";
import blueStar from "assets/icons/star-blue.svg";

export const CourseInfo = ({ course }) => {
  return (
    <Box className="course-info-wrapper">
      <Typography className="course-info-wrapper__title" variant="h6">
        {course.course}
      </Typography>

      <Box className="course-info-wrapper__details">
        <Box className="course-info-wrapper__details-item">
          <Box
            component="img"
            src={blueStar}
            className="course-info-wrapper__details-icon"
            id="star"
            alt="rating"
          />
          <Typography
            className="course-info-wrapper__details-text"
            variant="body2"
          >
            {course.rating}
          </Typography>
        </Box>

        <Box className="course-info-wrapper__details-item">
          <Box
            component="img"
            src={barchart}
            className="course-info-wrapper__details-icon"
            id="barchart"
            alt="barchart"
          />
          <Typography
            className="course-info-wrapper__details-text"
            variant="body2"
          >
            {course.level}
          </Typography>
        </Box>

        <Box className="course-info-wrapper__details-item">
          <Box
            component="img"
            src={clock}
            className="course-info-wrapper__details-icon"
            alt="Duration"
            id="clock"
          />
          <Typography
            className="course-info-wrapper__details-text"
            variant="body2"
          >
            {course.duration}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
