

import { Box } from "@mui/material";
import { Header } from "components/common/header/Header";
import Navbar from "components/common/navbar/Navbar";
import CoursesCard from "components/module/public/courses/Courses";
import React from "react";

const Courses = () => {


    return (
        <>
            <div className="public-courses">
                <CoursesCard />
            </div>
        </>);
};

export default Courses;
