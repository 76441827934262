import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Header } from "components/common/header/Header";
import PurchaseHistoryTable from "components/module/app/purchaseHistory/PurchaseHistoryTable";
import useGetRequest from "hooks/useGetRequest";
import LoadingContext from "context/loadingContext/LoadingContext";
import { API_ENDPOINTS } from "config/ApiConfig";

const PurchaseHistoryPage = () => {
  const [groupedCourses, setGroupedCourses] = useState([]);
  const [statusFilter, setStatusFilter] = useState("viewAll");

  const {
    data: paymentHistoryData,
    error: paymentHistoryDataError,
    loading: paymentHistoryDataLoading,
    getData: paymentHistoryDataPayload,
  } = useGetRequest(API_ENDPOINTS.FETCH_PAYMENT_HISTORY);

  const { isAppLoading } = useContext(LoadingContext);

  useEffect(() => {
    paymentHistoryDataPayload();
  }, [isAppLoading]);

  useEffect(() => {
    if (paymentHistoryData) {
      if (paymentHistoryData.success) {
        const courseMap = new Map();
  
        paymentHistoryData.courseDetails.forEach((course) => {
          const courseData = course.courseDetails || {};
          const masterCourse = courseData.MasterCourse || {};
          const paymentStatus = course.payment.payment_status;
          const courseEnrolledStatus = course.courseEnrolledStatus || {};
  
          const paymentStatusCheck = paymentStatus === "completed" ? "Paid" : "Canceled";
          const courseStatus = (courseEnrolledStatus.status || "n/a").toString();
  
          // Treat "in-progress" courses as enrolled courses
          const isEnrolled = courseStatus === "in-progress" || courseStatus === "enrolled";
  
          const courseItem = {
            id: course.payment.course_id,
            status: paymentStatusCheck,
            course: masterCourse.course_name || "N/A",
            rating: courseData.avg_review || "N/A",
            level: masterCourse.RefCourseDifficulty?.name || "N/A",
            duration: `${courseData.total_lessons || 0} Lessons`,
            progress: (course.userProgressCount / (courseData.total_lessons || 1)) * 100,
            invoice: `${course.payment.invoice_id || "N/A"}.pdf`,
            showDownload: paymentStatusCheck === "Paid",
            courseStatus: isEnrolled ? "enrolled" : courseStatus, // Adjusting the courseStatus here
          };
  
          // Use Map to prioritize "Paid" status if both exist
          if (!courseMap.has(courseItem.id) || courseMap.get(courseItem.id).status === "Canceled") {
            courseMap.set(courseItem.id, courseItem);
          }
        });
  
        const coursesGrouped = Array.from(courseMap.values());
  
        const filteredCourses =
          statusFilter === "viewAll"
            ? coursesGrouped
            : coursesGrouped.filter(
                (course) => (course.courseStatus || "n/a") === statusFilter
              );
  
        setGroupedCourses(filteredCourses);
      } else {
        console.error("Error in payment history data:", paymentHistoryData.error);
      }
    }
  }, [paymentHistoryData, statusFilter]);
  

  const handleStatusChange = (newStatus) => {
    // console.log("Status filter changed:", newStatus);
    setStatusFilter(newStatus);
  };

  return (
    <Box className="purchase-history-page">
      <Header
        title="Purchase History"
        subtitle="Manage your recent orders and invoices"
      />
      <PurchaseHistoryTable
        groupedCourses={groupedCourses}
        onStatusChange={handleStatusChange}
        statusFilter={statusFilter}
      />
    </Box>
  );
};

export default PurchaseHistoryPage;
