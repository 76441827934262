import React from "react";
import { RouterProvider } from "react-router-dom";
import AdminRoutes from "routes";
import CustomizedSnackbars from "components/common/snackBar/SnackBar";
import "styles/styles.scss";

function App() {
  return (
    <>
      <RouterProvider router={AdminRoutes} />
      <CustomizedSnackbars />
    </>
  );
}

export default App;
