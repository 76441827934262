import { Box } from "@mui/material";
import VideoPlayerSkeleton from "components/common/skeleton/videoPlayerSkeleton";
import VideoPlayer from "components/common/videoPlayer/VideoPlayer";
import VideoPlayerPublic from "components/common/videoPlayer/VideoPlayerPublic";
import VideoPlayerYoutube from "components/common/videoPlayer/VideoPlayerYoutube";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import React, { useContext, useEffect, useState } from "react";

const LessonVideoPlayer = ({ content }) => {
  const { lessonInfo } = useContext(LessonContext);
  const [videoURL, setVideoURL] = useState("");
  const [hostType, setHostType] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [contentDetails, setContentDetails] = useState(null);

  useEffect(() => {
    
    if (lessonInfo) {
      const contentArray = lessonInfo || []; 
      const foundContent = contentArray.find(item => item.content_id === content);
  
      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    } else {
      // console.log("lessonInfo is not available or does not contain content_id.");
    }
  }, [lessonInfo, content]); 

  useEffect(() => {
    if (contentDetails) {
      const hostType = String(contentDetails.MasterVideo.hosting_type);
      const videoFileName = String(contentDetails.MasterVideo.name);
      const thumbnailFileName = String(
        contentDetails.MasterVideo.MasterImage.name
      );

      setHostType(hostType);

      if (hostType === "1") {
        // Video hosted on your server
        setVideoURL(`${API_ENDPOINTS.GET_VIDEO_URL}/${videoFileName}`);
        setThumbnailURL(`${API_ENDPOINTS.GET_THUMBNAIL}/${thumbnailFileName}`);
      } else if (hostType === "2") {
        // YouTube video, assuming videoFileName contains the YouTube video ID
        setVideoURL(`${videoFileName}`);
        setThumbnailURL(`${API_ENDPOINTS.GET_THUMBNAIL}/${thumbnailFileName}`);
      }
    }
  }, [contentDetails]);

  return videoURL ? (
    <Box className="player-wrapper" id="lesson">
      {hostType === "1" ? (
        <VideoPlayerPublic
          videoUrl={videoURL}
          thumbnailUrl={thumbnailURL}
          content={content}
        />
      ) : (
        <VideoPlayerYoutube
          videoUrl={videoURL}
          thumbnailUrl={thumbnailURL}
          content={content}
        />
      )}
    </Box>
  ) : (
    <VideoPlayerSkeleton />
  );
};

export default LessonVideoPlayer;
