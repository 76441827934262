import React, { useState, useEffect } from 'react';
import { Box, InputBase, IconButton, Snackbar, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { io } from 'socket.io-client';
import { API_ENDPOINTS } from 'config/ApiConfig';

const socket = io(API_ENDPOINTS.SOCKET_URL); // Replace with your server URL

function MessageInput({ user_id, chatroom_id }) {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(''); // State for error messages

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
    });

    return () => {
      socket.off('connect');
    };
  }, []);

  const handleSendMessage = () => {
    if (message.trim() || file) {
      const messageData = {
        message,
        user_id,
        chatroom_id,
      };

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          messageData.file = {
            name: file.name,
            type: file.type,
            data: reader.result,
          };

          socket.emit('sendMessage', messageData, (response) => {
            console.log(response);
          });
          setMessage('');
          setFile(null);
        };
        reader.readAsDataURL(file);
      } else {
        socket.emit('sendMessage', messageData, (response) => {
          console.log(response);
        });
        setMessage('');
      }
    } else {
      console.error('Message is empty');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

      if (allowedTypes.includes(fileType)) {
        setFile(selectedFile);
      } else {
        setError('Only PNG, JPG, JPEG images, and PDFs are allowed.');
        setFile(null);
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <>
    {/* Display the selected file name */}
    {file && (
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, pl: 2 }}>
          <Typography variant="body2">{file.name}</Typography>
          <IconButton size="small" onClick={handleRemoveFile}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <InputBase
          fullWidth
          placeholder="Type a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
          sx={{ flex: 1 }}
        />
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".png, .jpg, .jpeg, .pdf" // Restrict to images and PDFs
        />
        <IconButton onClick={() => document.getElementById('fileInput').click()}>
          <AttachFileIcon />
        </IconButton>
        <IconButton onClick={handleSendMessage}>
          <SendIcon />
        </IconButton>
      </Box>

      

      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => setError('')}
          message={error}
        />
      )}
    </>
  );
}

export default MessageInput;
