import * as yup from "yup";

//regex for email
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//schema for settings -security
export const securityFormSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Current Password is required")
    .min(8, "Password should be at least 8 characters")
    .max(20, "Password should not exceed 20 characters")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .notOneOf([" "], "Password should not contain spaces"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password should be at least 8 characters")
    .max(20, "Password should not exceed 20 characters")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .notOneOf([" "], "Password should not contain spaces"),

  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

//security -profile schema
export const profileFormSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .min(1, "First name should be at least 1 character")
    .max(30, "First name should not exceed 30 characters")
    .matches(/^[A-Za-z ]*$/, "Alphabetic characters only")
    // .test("no-trailing-spaces", "No trailing spaces", (value) => {
    //   return value === undefined || value.trim() === value;
    // })
    // .test(
    //   "single-spaces-between-words",
    //   "Single spaces between words",
    //   (value) => {
    //     return value === undefined || /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
    //   }
    // )
    .test(
      "not-equal-to-lastName",
      "First name and Last name should not be equal",
      function (value) {
        return value !== this.parent.lastName;
      }
    ),

  lastName: yup
    .string()
    .required("Last name is required")
    .min(1, "Last name should be at least 1 character")
    .max(30, "Last name should not exceed 30 characters")
    .matches(/^[A-Za-z ]*$/, "Alphabetic characters only")
    // .test("no-trailing-spaces", "No trailing spaces", (value) => {
    //   return value === undefined || value.trim() === value;
    // })
    // .test(
    //   "single-spaces-between-words",
    //   "Single spaces between words",
    //   (value) => {
    //     return value === undefined || /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(value);
    //   }
    // )
    .test(
      "not-equal-to-firstName",
      "First name and Last name should not be equal",
      function (value) {
        return value !== this.parent.firstName;
      }
    ),

  dateOfBirth: yup
    .date()
    .typeError("Invalid date")
    .required("Date of Birth is required")
    .test(
      "ageRestriction",
      "Students should be at least 6 years old to enroll",
      function (value) {
        const today = new Date();
        let age = today.getFullYear() - value.getFullYear();
        const monthDiff = today.getMonth() - value.getMonth();

        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < value.getDate())
        ) {
          age--;
        }

        return age >= 6;
      }
    )
    .max(new Date(), "Date of Birth cannot be in the future"),

  email: yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Enter a valid email")
    .trim(),
});
