import { createContext, useEffect, useState } from "react";

const CourseViewContext = createContext();

export default CourseViewContext;

export const CourseViewProvider = ({ children }) => {
  const [totalPercentage, setTotalPercentage] = useState("");

  // State to store course data
  const [courseData, setCourseData] = useState({
    course_name: "",
    course_difficulty: "",
    course_thumbnail: "",
    courseStat: {},
    status: "",
  });

  // State to store additional transformed data
  const [transformedData, setTransformedData] = useState({
    activeChapter: null,
    activeLesson: null,
    timeOfIncompleteLessons: 0,
    timeSpent: 0,
    totalLessons: 0,
    completedLessons: 0,
  });

  // Function to reset all states
  const resetState = () => {
    // setCourseData({
    //   course_name: "",
    //   course_difficulty: "",
    //   course_thumbnail: "",
    //   courseStat: {},
    //   status: "",
    // });
    setTransformedData({
      activeChapter: null,
      activeLesson: null,
      timeOfIncompleteLessons: 0,
      timeSpent: 0,
      totalLessons: 0,
      completedLessons: 0,
    });
    setTotalPercentage("");
  };

  const transformData = (data) => {
    let activeChapter = null;
    let activeLesson = null;
    let timeOfIncompleteLessons = 0;
    let timeSpent = 0; // Total time spent
    let totalLessons = 0; // Total lessons
    let completedLessons = 0; // Completed lessons

    // Extract total lessons, completed lessons, and time spent from the provided data
    totalLessons = data.totalLessons || 0;
    completedLessons = data.completedLessons || 0;

    // Handle potential issues with timeSpent, ensuring it defaults to 0 if invalid
    timeSpent = data.totalTimeSpent || 0;

    // Find the active chapter
    for (const chapter of data.sortedChapter) {
      if (chapter.isActive) {
        activeChapter = chapter;
        activeLesson = chapter.lessons.find((lesson) => lesson.isActive);

        // Calculate time for incomplete lessons
        timeOfIncompleteLessons = chapter.lessons
          .filter((lesson) => !lesson.isCompleted)
          .reduce(
            (total, lesson) => total + lesson.estimated_completion_time,
            0
          );
        break; // Exit loop once the active chapter is found
      }
    }

    // Update transformed data state
    if (activeChapter && activeLesson) {
      setTransformedData({
        activeChapter,
        activeLesson,
        timeOfIncompleteLessons,
        timeSpent,
        totalLessons,
        completedLessons,
      });
    }
  };

  return (
    <CourseViewContext.Provider
      value={{
        courseData,
        setCourseData,
        transformData,
        transformedData,
        setTotalPercentage,
        setTransformedData,
        totalPercentage,
        resetState, // Expose the resetState function
      }}
    >
      {children}
    </CourseViewContext.Provider>
  );
};
