import { useState } from "react";
import axios from "axios";

const useGetRequest = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async (userId = null) => {
    setLoading(true);
    setError(null);

    try {
      const fullUrl = userId ? `${url}/${userId}` : url;
      const response = await axios.get(fullUrl);

      setData(response.data);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.log(error.response.data);
        setError(error.response.data);
        console.log(error.response.status);
        setError(error.response.status);
        // console.log(error.response.headers);
        setError(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
        setError(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        setError(error.message);
      }
      console.log(error.config);
      setError(error.config);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, getData };
};

export default useGetRequest;
