import CourseDetailsPage from "pages/appPage/courseDetailsPage/CourseDetailsPage";
import VerifyCertificatePage from "pages/publicpage/certificate/verify-certificate";
import Courses from "pages/publicpage/Course";

export const publicRoutes = [
  
  {
    path: "courses",
    element: <Courses />,
  },
  {
    path: "view-course/:slug",
    element: <CourseDetailsPage />,
  },
  {
    path: "verify-certificate",
    element: <VerifyCertificatePage />,
  },
];
