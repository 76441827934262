import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";
import Clock from "assets/icons/clock.svg";
import Star from "assets/icons/star.svg";
import UserContext from "context/userContext/UserContext";

const AvailableCourseCard = ({
  courseName,
  level,
  image,
  rating,
  duration,
  description,
  slug,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isViewCourse = location.pathname.includes("/courses");

  const myCourse = location.pathname.includes("/app/my-course");

  const handleEnrollNow = () => {
    if (isViewCourse) {
      const intendedUrl = `/view-course/${slug}`;
      navigate(intendedUrl); // Navigate to the course directly
    } 
    if(myCourse) {
      const intendedUrl = `/app/course/${slug}`;
      navigate(intendedUrl);
    }
  };

  return (
    <Box onClick={handleEnrollNow} sx={{ cursor: "pointer" }}>
      <Card className="available-course-card">
        <Box className="available-course-card__media-container">
          <CardMedia
            className="available-course-card__media"
            component="img"
            height="200"
            image={image}
          />
          {/* <Typography className="available-course-card__course-title text"></Typography> */}
        </Box>
        <CardContent className="available-course-card__card-content">
          <Typography
            className="available-course-card__course-name text-semi-bold"
            mb={0.5}
            variant="h6"
          >
            {courseName}
          </Typography>
          <Box className="available-course-card__info">
            <Box
              className="available-course-card__info__rating-icon"
              component="img"
              src={Star}
            />
            <Typography
              className="available-course-card__info__rating text"
              variant="body2"
            >
              {rating}
            </Typography>
            <Box
              className="available-course-card__info__duration-icon"
              component="img"
              src={Clock}
            />
            <Typography
              className="available-course-card__info__duration text"
              variant="body2"
            >{`${duration}`}</Typography>
          </Box>
          <Typography
            className="available-course-card__description text"
            variant="caption"
          >
            {description}...
          </Typography>
          <Box mt={1}>
            <Button
              className="available-course-card__enroll-now-button text"
              variant="outlined"
              onClick={handleEnrollNow}
            >
              Enroll Now
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AvailableCourseCard;
