import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import quiz from "assets/images/quiz.svg";
import chevronRight from "assets/icons/chevron-right.svg";
const DashboardQuizCard = ({ data }) => {
  return (
    <>
      {data.takeAssessment.map((assessment, index) => (
        <Box className="dashboard-quiz">
          <Box key={index}>
            <Box component="img" src={quiz} alt="quiz" className="dashboard-quiz__image" />
            <Box className="dashboard-quiz__content">
              <Typography className="dashboard-quiz__content--title">
                {assessment.MasterCourse.course_name || 'Assessment Quiz'}
              </Typography>
              <Typography className="dashboard-quiz__content--description">
                Unlock insights into your abilities and personal growth potential with this comprehensive quiz. This assessment will provide valuable feedback on key aspects of your personal and professional skills.
              </Typography>
            </Box>
            <Box className="dashboard-quiz__assessment">
              {/* Construct a dynamic link using the course slug */}
              <Link className="dashboard-quiz__assessment-link" to={`/app/course/${assessment.MasterCourse.slug}/learn/${assessment.groupedChapterDetails[0].lessons[0].lesson_slug}`}>
                <Typography className="dashboard-quiz__assessment-link--text">
                  Take Assessment Quiz
                </Typography>
                <Box
                  className="dashboard-quiz__assessment-link--button"
                  component="img"
                  src={chevronRight}
                  alt="chevron"
                />
              </Link>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default DashboardQuizCard;
