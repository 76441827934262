import React, { createContext, useState, useCallback, useEffect } from "react";

const LessonContext = createContext();

export default LessonContext;

export const LessonProvider = ({ children }) => {
  const [lessonInfo, setLessonInfo] = useState([]);
  const [sortedLessonData, setSortedLessonData] = useState([]);
  const [lessonCompletionData, setLessonCompletionData] = useState([]);
  const [lessonIdentifiers, setLessonIdentifiers] = useState({
    course_id: null,
    chapter_id: null,
    lesson_id: null,
  });

  const [isNavigating, setIsNavigating] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false); // Store full-screen mode state
  const [fullScreenContent, setFullScreenContent] = useState(null); // Store full-screen content
  const [hiddenContent, setHiddenContent] = useState([]); // Store hidden content

  const triggerNavigation = () => {
    setIsNavigating(true);
  };

  const updateLessonCompletionData = (content_id) => {
    console.log("function called");
    setLessonCompletionData((prevData) =>
        prevData.map((item) =>
            item.content_id === content_id
                ? { ...item, is_viewed: true }
                : item
        )
    );

    console.log("lessonCompletionData in side the function:", lessonCompletionData);
};

  const storeLessonIdentifiers = (identifiers) => {
    setLessonIdentifiers((prev) => ({
      ...prev,
      ...identifiers,
    }));
  };
  
  const addLessonData = (dataArray) => {
    const lessonData = dataArray.map(item => {
        return {
            ...item,
            is_viewed: false
        };
    });
    setLessonCompletionData(lessonData);
}
  // const handleQuizSubmission = useCallback(
  //   (contentId) => {
  //     setLessonInfo((prevLessonInfo) =>
  //       prevLessonInfo.map((lesson) => {
  //         // Only update the specific lesson with matching content_id
  //         if (lesson.content_id === contentId) {
  //           return { ...lesson, is_full_screen: false }; // Set is_full_screen to false
  //         }
  //         return lesson;
  //       })
  //     );
      
  //     setIsFullScreen(false); // Exit full-screen mode
  //     setFullScreenContent(null); // Clear full-screen content
  //   },
  //   [setLessonInfo, setIsFullScreen, setFullScreenContent]
  // );
  
  const handleQuizSubmission = useCallback(
    (contentId) => {
      setLessonInfo((prevLessonInfo) => {
        const updatedLessons = prevLessonInfo.filter(
          (lesson) => lesson.content_id !== contentId // Remove the lesson with matching content_id
        );
  
        // Check for any remaining full-screen lessons
        const fullScreenLesson = updatedLessons.find(
          (lesson) => lesson.is_full_screen === true
        );
  
        // Update the full-screen state based on the remaining lessons
        if (fullScreenLesson) {
          setIsFullScreen(true);
          setFullScreenContent(fullScreenLesson);
        } else {
          setIsFullScreen(false);
          setFullScreenContent(null);
        }
  
        return updatedLessons; // Return the updated lesson info
      });
    },
    [setLessonInfo, setIsFullScreen, setFullScreenContent]
  );
  
  const handleDisableFullScreen = useCallback(
    (contentId) => {
      setLessonInfo((prevLessonInfo) => {
        // Map through the lessons and update `is_full_screen` to `false` for the matching content_id
        const updatedLessons = prevLessonInfo.map((lesson) =>
          lesson.content_id === contentId
            ? { ...lesson, is_full_screen: false }
            : lesson
        );
  
        // Check for any remaining full-screen lessons
        const fullScreenLesson = updatedLessons.find(
          (lesson) => lesson.is_full_screen === true
        );
  
        // Update the full-screen state based on the remaining lessons
        if (fullScreenLesson) {
          setIsFullScreen(true);
          setFullScreenContent(fullScreenLesson);
        } else {
          setIsFullScreen(false);
          setFullScreenContent(null);
        }
  
        return updatedLessons; // Return the updated lesson info
      });
    },
    [setLessonInfo, setIsFullScreen, setFullScreenContent]
  );

  
  useEffect(() => {
    const fullScreenLesson = lessonInfo.find(
      (lesson) => lesson.is_full_screen === true
    );

    if (fullScreenLesson) {
      setIsFullScreen(true);
      setFullScreenContent(fullScreenLesson);
    } else {
      setIsFullScreen(false);
      setFullScreenContent(null);
    }
  }, [lessonInfo]); 
  
  // Log the current state of isFullScreen whenever it changes
  useEffect(() => {
    console.log("lessonCompletionData:", lessonCompletionData);
  }, [lessonCompletionData]); // Dependency array triggers whenever isFullScreen updates

  const markLessonAsComplete = (lessonId) => {
    let shouldUnlockNextLesson = false; // Flag to track whether the next lesson should be unlocked
    
    setSortedLessonData((prevData) => {
      return prevData.map((chapter, chapterIndex, chaptersArray) => {
        const updatedLessons = chapter.lessons.map((lesson, lessonIndex, lessonsArray) => {
          if (lesson.lesson_id == lessonId) {
            // Mark the current lesson as completed
            shouldUnlockNextLesson = true; // Set flag to unlock the next lesson
            // console.log(`Marking lesson ${lesson.lesson_id} as completed`);
            return {
              ...lesson,
              isCompleted: true,
            };
          }
  
          // If the flag is set, unlock this lesson and reset the flag
          if (shouldUnlockNextLesson) {
            shouldUnlockNextLesson = false; // Reset the flag after unlocking
            // console.log(`Unlocking lesson ${lesson.lesson_id}`);
            return {
              ...lesson,
              isLocked: false,
            };
          }
  
          return lesson;
        });
  
        // Check if all lessons in the current chapter are completed
        const allLessonsCompleted = updatedLessons.every(lesson => lesson.isCompleted);
  
        // If all lessons in the current chapter are completed, mark the chapter as completed
        if (allLessonsCompleted) {
          chapter = {
            ...chapter,
            isCompleted: true, // Set chapter as completed
          };
        }
  
        // If the last lesson in the current chapter has been completed and there is a next chapter
        if (shouldUnlockNextLesson && chapterIndex < chaptersArray.length - 1) {
          const nextChapter = chaptersArray[chapterIndex + 1];
          if (nextChapter.lessons.length > 0) {
            // Unlock the first lesson of the next chapter
            nextChapter.lessons[0] = {
              ...nextChapter.lessons[0],
              isLocked: false,
            };
            // console.log(`Unlocking lesson ${nextChapter.lessons[0].lesson_id} in the next chapter`);
          }
          shouldUnlockNextLesson = false; // Reset the flag
        }
  
        // console.log('sortedLessonData -->', prevData); // Output the current state of sortedLessonData
        return {
          ...chapter,
          lessons: updatedLessons,
        };
      });
    });
  };
  
  

  return (
    <LessonContext.Provider
      value={{
        storeLessonIdentifiers,
        lessonIdentifiers,
        isNavigating,
        triggerNavigation,
        lessonInfo,
        setLessonInfo,
        lessonCompletionData,
        updateLessonCompletionData,
        sortedLessonData,
        setSortedLessonData,
        fullScreenContent,
        setFullScreenContent,
        isFullScreen,
        setIsFullScreen,
        handleQuizSubmission,
        handleDisableFullScreen,
        hiddenContent,
        setHiddenContent,
        markLessonAsComplete,
        addLessonData
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};
