import { SkeletonLoader } from "components/common/skeleton/SkeletonCard";
import { API_ENDPOINTS } from "config/ApiConfig";
import useGetRequest from "hooks/useGetRequest";
import React, { useEffect, useRef, useState } from "react";
import { Typography, Button, Container, Box, TextField } from "@mui/material";
import CertificateVerificationCard from "./certificatedetails";
import CertificateCardSkeleton from "components/common/skeleton/verifycertificateSkelton";
import { useLocation } from "react-router-dom";

const VerifyComponent = () => {
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [inputLoader, setInputLoader] = useState(false);
    const [certificateCode, setCertificateCode] = useState(""); // Store the certificate code
    const [certificateData, setCertificateData] = useState(null); // Store the certificate data
    const [error, setError] = useState(null); // Store error if any

    const inputRefs = useRef([]); // Ref to store the input fields

    const certificateQueryCode = new URLSearchParams(location.search).get("code");

    useEffect(() => {
        const fetchCertificateData = async () => {
            if (!certificateQueryCode) return;

            setLoading(true);
            try {
                await certificatePayload(certificateQueryCode);
                setInputLoader(true);
            } catch (error) {
                setError("Failed to verify the certificate.");
            } finally {
                setLoading(false);
            }
        };

        fetchCertificateData();
    }, [certificateQueryCode]);

    // Function to handle input change
    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (value.length === 1 && index < 10) {
            inputRefs.current[index + 1].focus(); // Move to the next input if the length of the current input is 1
        } else if (value.length === 0 && index > 0) {
            inputRefs.current[index - 1].focus(); // Move to the previous input if backspace is pressed
        }

        const updatedCode = [...certificateCode];
        updatedCode[index] = value.toUpperCase(); // Ensure uppercase
        setCertificateCode(updatedCode.join(""));
    };

    // Function to handle key down (e.g., backspace)
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && e.target.value === '') {
            inputRefs.current[index - 1].focus(); // Move to previous input
        }
    };

    const {
        data: getCertificateData,
        loading: loadingCertificate,
        error: errorFetchingCertificate,
        getData: certificatePayload,
    } = useGetRequest(API_ENDPOINTS.VERIFY_CERTIFICATE);

    // Function to handle verification click
    const handleVerifyClick = async () => {
        // console.log("certificateCode-> ", certificateCode)
        if (certificateCode.length !== 11) {
            setError("Please enter a valid 11-character certificate code.");
            return;
        }
        try {
            setLoading(true);
            await certificatePayload(certificateCode);
            setCertificateData(getCertificateData);
            setInputLoader(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError("Failed to verify the certificate.");
        }
    };

    useEffect(() => {
        if (getCertificateData) {
            setCertificateData(getCertificateData);
            setInputLoader(true);
            setLoading(false); // Stop loading when data is available
        }
    }, [getCertificateData]);

    if (loading) {
        return <CertificateCardSkeleton />;
    }

    if (!inputLoader) {
        return (
            <Container maxWidth="sm" className="certificate-container">
                <Typography className="certificate-title" gutterBottom>
                    Certificate Verification
                </Typography>
                <Box className="verification-box">
                    <Box className="input-group">
                        {/* Render 11 input fields */}
                        {Array.from({ length: 11 }).map((_, index) => (
                            <TextField
                                key={index}
                                className="input-field"
                                inputProps={{ maxLength: 1, style: { textAlign: 'center', color: '#fff', textTransform: 'uppercase' } }}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                inputRef={(el) => (inputRefs.current[index] = el)} // Assign each input field to the ref
                            />
                        ))}
                    </Box>
                    <Button variant="contained" className="verify-button" onClick={handleVerifyClick}>
                        Verify Certificate
                    </Button>
                    {error && <Typography color="error">{error}</Typography>}
                </Box>
            </Container>
        );
    }


    return (
        <>
            {certificateData && <CertificateVerificationCard data={certificateData} />} {/* Render certificate data */}
        </>
    );

};

export default VerifyComponent;
