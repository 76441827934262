import React, { useState, useContext, useRef } from "react";
  // import Plyr from "plyr-react";
  // import 'plyr-react/plyr.css';
import LessonContext from "context/lessonContext/LessonContext";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ReactPlayer from "react-player";

const VideoPlayerYoutube = ({ videoUrl, thumbnailUrl }) => {
  const { updateLessonCompletionData } = useContext(LessonContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const playerRef = useRef(null);


  return (
    <ReactPlayer
      className="react-player"
      url={`https://youtube.com/embed/${videoUrl}?rel=0&enablejsapi=1`}
      style={{ borderRadius: "15px" }}
      width="100%"
      height="100%"
      light={
        <img
          src={thumbnailUrl}
          alt="Thumbnail"
          className="react-player__preview--image"
        />
      }
      config={{
        youtube: {
          playerVars: {
            rel: 0,            // Disable related videos
            showinfo: 0,       // Hide video title and player actions
            modestbranding: 1  // Hide YouTube logo
          }
        }
      }}
      controls={true}
    />
  );
};

export default VideoPlayerYoutube;
