import React, { useContext, useEffect } from "react";
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from "@mui/material";
import AppContext from "context/appContext/AppContext";


const Footer = () => {
  const { currentUrl, setCurrentUrl } = useContext(AppContext);
  const location = useLocation(); // Get the current location

  // Capture the entire URL
  const currentUrl1 = `${window.location.origin}${location.pathname}${location.search}`; 
  
  // Check if "learn" is present in the URL
  const showReportContent = location.pathname.includes('learn');

  useEffect(() => {
    // Update the context only if "learn" is present
    if (showReportContent) {
      setCurrentUrl(currentUrl1);
    }
  }, [currentUrl1, showReportContent, setCurrentUrl]);

  return (
    <Box className="footer-wrapper" display="flex" justifyContent="space-between" alignItems="center">
      <Box flexGrow={1} display="flex" justifyContent={{ xs: 'center', sm: 'center', md: 'start' }}>
        <Typography variant="caption" className="footer-wrapper__text" sx={{marginLeft: 4}}>
          {"Copyright © 2024 "}
          <a
      className="footer-wrapper__link"
      href="https://mytrueskills.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      My True Skills
    </a>
          {" All Rights Reserved"}
        </Typography>
      </Box>
      {showReportContent && (
        <Typography variant="caption" className="footer-wrapper__text" sx={{marginRight: 4}}>
          <RouterLink
            className="footer-wrapper__link"
            to={`/app/report-content`} // Pass the encoded URL as part of the path
          >
            Report Content
          </RouterLink>
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
