import { Avatar } from "@mui/material";
import React from "react";

const ProfilePicture = ({ firstName, lastName, imageSize, imageUrl }) => {
  // Check if firstName and lastName have values, otherwise use default values
  const fName = firstName ? firstName[0].toUpperCase() : "N";
  const lName = lastName ? lastName[0].toUpperCase() : "A";
  const initials = `${fName}${lName}`;
  return (
    <Avatar
      className="dropdown__avatar"
      src={imageUrl || null}
      sx={{
        bgcolor: imageUrl == null ? "transparent" : "#2B3D4F",
        width: imageSize,
        height: imageSize,
      }}
    >
      {imageUrl !== null ? initials : imageUrl}
    </Avatar>
  );
};

export default ProfilePicture;
