import React from "react";
import { Box, Typography } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box className="error-page">
      <Typography className="error-page__title" variant="h3">
        404 - Page Not Found
      </Typography>
      <Typography className="error-page__caption" variant="h6">
        The page you are looking for does not exist
      </Typography>
    </Box>
  );
};

export default ErrorPage;
