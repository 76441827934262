import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  //color - progress
  color: "#39CCCC",
  "& .MuiCircularProgress-circle": {
    // Curved border radius
    strokeLinecap: "round",
    // for smooth animation
    transition: "stroke-dashoffset 0.5s ease 0s", // Smooth animation
  },
}));

const BackgroundCircularProgress = styled(CircularProgress)(({ theme }) => ({
  // faded background color
  color: "#EAECF0",
  position: "absolute",
  left: 0,
}));

const CourseProgressCircle = ({
  finalProgress,
  size = 120,
  thickness = 4,
  timeline = "",
  timelinepercentage = "",
}) => {
  //for animation, we need set the state to zero at first
  const [progress, setProgress] = useState(0);

  //using useEffect  to render the component using animation evertime the page reloads and change in the final progress val
  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress(finalProgress);
    }, 100); // Small delay to ensure component is mounted

    return () => clearTimeout(timer);
  }, [finalProgress]);

  return (
    <Box className="circular-progress-wrapper">
      <BackgroundCircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={thickness}
      />
      <StyledCircularProgress
        variant="determinate"
        value={progress}
        size={size}
        thickness={thickness}
      />
      <Box className="circular-progress-wrapper__text-wrapper">
        <Typography
          id={timeline}
          variant="caption"
          component="div"
          className="circular-progress-wrapper__text-wrapper--text"
        >
          Course Progress
        </Typography>
        <Typography
          id={timelinepercentage}
          variant="h4"
          component="div"
          className="circular-progress-wrapper__text-wrapper--percentage"
        >
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CourseProgressCircle;
