import React, { useContext, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useStopwatch } from "react-timer-hook";
import usePostRequest from "hooks/usePostRequest";
import useGetRequest from "hooks/useGetRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import LoadingContext from "context/loadingContext/LoadingContext";
import LessonContext from "context/lessonContext/LessonContext";
import { Box, Divider, Grid, Skeleton } from "@mui/material";
import CourseTimelineCard from "components/module/app/courseChapter/CourseTimelineCard";
import NavigateChapter from "components/module/app/courseChapter/NavigateChapter";
import PDFComp from "components/module/app/courseChapter/PDFComp";
import ChapterContent from "components/module/app/courseChapter/ChapterContent";
import LessonVideoPlayer from "components/module/app/courseChapter/LessonVideoPlayer";
import FactOpinionQuiz from "components/courseComponents/quizComp/FactOpinionQuiz";
import QuestionAnswer from "components/courseComponents/quizComp/QuestionAnswer";
import TrueOrFalse from "components/courseComponents/quizComp/TrueOrFalse";
import MultipleLongAnswerQuiz from "components/courseComponents/quizComp/MultipleLongAnswerQuiz";
import MultipleOptionsQuiz from "components/courseComponents/quizComp/MultipleOptionsQuiz";
import QuizFeedback from "components/courseComponents/quizComp/QuizFeedback";
import NavigateChapterBottom from "components/module/app/courseChapter/NavigateChapterBottom";
import AnswerField from "components/courseComponents/quizComp/AnswerField";
import VideoPlayerSkeleton from "components/common/skeleton/videoPlayerSkeleton";
import EmojiQuiz from "components/courseComponents/quizComp/EmojiQuiz";
import ComponentSkeleton from "components/common/skeleton/ComponentSkeleton";
import RangeQuizSlider from "components/courseComponents/quizComp/RangeQuiz";
import QuizBasedOnStory from "components/courseComponents/quizComp/QuizBasedOnStory";
import ImageContent from "components/courseComponents/quizComp/Image";
import HtmlCodeQuiz from "components/courseComponents/quizComp/HtmlTableQuiz";
import PledgeHtmlCodeQuiz from "components/courseComponents/quizComp/PledgeTableQuiz";
const CourseLessonPage = () => {
  const navigate = useNavigate();
  const { chapter, lesson } = useParams();
  const progressTimeoutRef = useRef(null);
  const startTimeRef = useRef(Date.now());
  
  // Combine related state to reduce renders
  const [pageState, setPageState] = useState({
    chapterSlug: "",
    lessonSlug: "",
    isContentLoading: false,
    lessonData: [],
    totalLessonCompleted: false
  });

  // Progress tracking state
  const [progressState, setProgressState] = useState({
    hasScrolledToBottom: false,
    isCooldownActive: false,
  });

  // Context setup
  const { notifyUser } = useContext(NofityUserContext);
  const { isAppLoading } = useContext(LoadingContext);
  const { 
    lessonInfo, 
    setLessonInfo,
    lessonCompletionData,
    storeLessonIdentifiers,
    lessonIdentifiers,
    markLessonAsComplete,
    addLessonData
  } = useContext(LessonContext);

  // API hooks
  const {
    data: lessonData,
    postData: postLessonData,
  } = usePostRequest(API_ENDPOINTS.SAVE_LESSON_PROGRESS);

  const {
    data: chapterData,
    error: chapterError,
    loading: loadingChapter,
    getData: chapterPayload,
  } = useGetRequest(API_ENDPOINTS.USER_COURSE_CHAPTER_DETAILS);

  // Optimized scroll handler with debounce
  const handleScroll = useCallback(() => {
    if (progressState.isCooldownActive) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setProgressState(prev => ({ ...prev, hasScrolledToBottom: true }));
    }
  }, [progressState.isCooldownActive]);

  // Memoized progress update function
  const sendProgressUpdate = useCallback(async () => {
    if (!lessonIdentifiers || progressState.isCooldownActive) return;

    const timeSpentMinutes = Math.floor(
      (Date.now() - startTimeRef.current) / 60000
    ).toString();

    const payload = {
      course_id: lessonIdentifiers.course_id?.toString(),
      chapter_id: lessonIdentifiers.chapter_id?.toString(),
      lesson_id: lessonIdentifiers.lesson_id?.toString(),
      time_spent: timeSpentMinutes,
      completed_status: "1"
    };

    try {
      setProgressState(prev => ({ ...prev, isCooldownActive: true }));
      await postLessonData(payload);
      startTimeRef.current = Date.now(); // Reset timer after successful update

      // Use ref for timeout to prevent stale closures
      progressTimeoutRef.current = setTimeout(() => {
        setProgressState(prev => ({
          ...prev,
          isCooldownActive: false,
          hasScrolledToBottom: false
        }));
      }, 10000);
    } catch (error) {
      console.error("Error updating progress:", error);
      setProgressState(prev => ({ ...prev, isCooldownActive: false }));
    }
  }, [lessonIdentifiers, postLessonData]);

  // Cleanup function
  useEffect(() => {
    return () => {
      if (progressTimeoutRef.current) {
        clearTimeout(progressTimeoutRef.current);
      }
    };
  }, []);

  // Scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // Progress update trigger
  useEffect(() => {
    if (progressState.hasScrolledToBottom && !progressState.isCooldownActive) {
      sendProgressUpdate();
    }
  }, [progressState.hasScrolledToBottom, progressState.isCooldownActive, sendProgressUpdate]);

  // Page unload handler
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sendProgressUpdate();
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [sendProgressUpdate]);

  // Update slugs when route params change
  useEffect(() => {
    if (chapter && lesson) {
      setPageState(prev => ({
        ...prev,
        chapterSlug: chapter,
        lessonSlug: lesson
      }));
    }
  }, [chapter, lesson]);

  // Fetch course details
  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (!isAppLoading && pageState.chapterSlug && pageState.lessonSlug) {
        setPageState(prev => ({ ...prev, isContentLoading: true }));
        
        try {
          await chapterPayload(`${pageState.chapterSlug}/${pageState.lessonSlug}`);
        } catch (err) {
          if (err.response) {
            notifyUser("Error", "An error occurred while fetching course details.", "error");
          } else if (err.request) {
            notifyUser("Network Error", "Network error, please try again.", "warning");
          } else {
            notifyUser("Unexpected Error", `Unexpected error: ${err.message}`, "error");
          }
        } finally {
          setPageState(prev => ({ ...prev, isContentLoading: false }));
        }
      }
    };

    fetchCourseDetails();
  }, [isAppLoading, pageState.chapterSlug, pageState.lessonSlug]);

  // Process chapter data
  useEffect(() => {
    if (!loadingChapter && chapterData && !chapterError) {
      const sortedLessonData = [...chapterData].sort(
        (a, b) => a.content_order - b.content_order
      );

      setPageState(prev => ({ ...prev, lessonData: sortedLessonData }));
      setLessonInfo(sortedLessonData);
      addLessonData(sortedLessonData);
      

      if (chapterData[0]) {
        storeLessonIdentifiers({
          lesson_id: chapterData[0].lesson_id,
          chapter_id: chapterData[0].MasterLesson?.chapter_id
        });
      }
    }
  }, [chapterData, loadingChapter, chapterError]);

  
  useEffect(() => {
    const totalLessons = Object.keys(lessonCompletionData).length;
    const completedLessons = Object.values(lessonCompletionData)
      .filter(lesson => lesson.status === 1).length;

    if (totalLessons > 0) {
      const completionPercentage = (completedLessons / totalLessons) * 100;
      setPageState(prev => ({
        ...prev,
        totalLessonCompleted: completionPercentage >= 75
      }));
    }
  }, [lessonCompletionData]);

  useEffect(() => {
    if (lessonData) {
      markLessonAsComplete(lessonData.lesson_id)
    }
  },[lessonData]); 

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressState(prev => ({ ...prev, isCooldownActive: false }));
      sendProgressUpdate();
    }, 50000);
    return () => {
      clearInterval(interval);
    };
  }, [sendProgressUpdate]);
  

  // Memoize ContentRenderer to prevent unnecessary re-renders
  const MemoizedContentRenderer = useMemo(() => {
    return function ContentRenderer({ content }) {
      const {
        fullScreenContent,
        setFullScreenContent,
        isFullScreen,
        setIsFullScreen,
        handleQuizSubmission,
      } = useContext(LessonContext);
      const { RefContentType, is_full_screen } = content;

      useEffect(() => {
        if ((isFullScreen === true || isFullScreen === 1) && 
            RefContentType.name === "quiz_singleAnswer") {
          setIsFullScreen(true);
          setFullScreenContent(content);
        }
      }, [is_full_screen, RefContentType.name]);

      if (isFullScreen && fullScreenContent && 
          fullScreenContent.content_id !== content.content_id) {
        return null;
      }

     switch (RefContentType.name) {
      case "content_ppt":
        return <PDFComp content={content.content_id} />;
      case "content_video":
        return <LessonVideoPlayer content={content.content_id} />;
      case "content_text":
        return <ChapterContent content={content.content_id} />;
      case "quiz_singleAnswer":
        return (
          <QuestionAnswer
            content={content.content_id}
            onQuizSubmit={() => handleQuizSubmission(content.content_id)}
          />
        );
      case "quiz_options":
        if (content.contentDetails) {
          if (content.contentDetails.trueOrfalse) {
            return <TrueOrFalse content={content.content_id} />;
          } else if (content.contentDetails.multipleOptions) {
            return <MultipleOptionsQuiz content={content.content_id} />;
          } else if (content.contentDetails.factorOpinion) {
            return <FactOpinionQuiz content={content.content_id} />;
          } else if (content.contentDetails.nOptions) {
            // Handle nOptions quiz type
            // return <NOptionsQuiz content={content} />;
          } else if (content.contentDetails.emojiOptions) {
            return <EmojiQuiz content={content.content_id} />;
          }
        }
        break;
      case "quiz_longAnswer":
        if (content.contentDetails) {
          const detail = content.contentDetails[0];

          if (detail.is_question_html) { // Checking as a number
            return <AnswerField content={content.content_id} />;
          } else if (detail.is_range_input) {
            return <RangeQuizSlider content={content.content_id} />;
          } else {
            return <MultipleLongAnswerQuiz content={content.content_id} />;
          }
        }
        break;
      case "quiz_story":
        return <QuizBasedOnStory content={content.content_id} />;
      case "content_image":
        return <ImageContent content={content.content_id} />;
      case "content_html":
        if (content?.contentDetails?.htmlCodeDetails?.secondary_info) {
          let secondaryInfo;

          // Log the initial secondary_info value
          // console.log("Raw secondary_info:", content.contentDetails.htmlCodeDetails.secondary_info);

          // Try parsing secondary_info if it's a JSON string
          try {
            secondaryInfo = JSON.parse(content.contentDetails.htmlCodeDetails.secondary_info);
          } catch (error) {
            console.error("Error parsing secondary_info JSON:", error);
            secondaryInfo = {}; // Fallback to an empty object if parsing fails
          }

          // Log the parsed secondaryInfo object
          // console.log("Parsed secondaryInfo:", secondaryInfo);

          // Now you can safely check properties within secondaryInfo
          if (secondaryInfo.add_words_pledge === 1) {
            // console.log("add_words_pledge is 1, returning PledgeHtmlCodeQuiz");
            return <PledgeHtmlCodeQuiz content={content.content_id} />;
          } else {
            return <HtmlCodeQuiz content={content.content_id} />;
          }
        }
        break;

      default:
        return <div>No content available.</div>;
    }
    };
  }, []);

  return (
    <Box>
      <Grid
        container
        fluid
        sx={{
          margin: "0 -3vh",
          width: "calc(100% + 6vh)",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Grid item xs={12} sm={4} md={3} sx={{ order: { xs: 2, sm: 1, md: 1 } }}>
          <CourseTimelineCard />
        </Grid>
        
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            mr: -0.2,
            opacity: "1",
            zIndex: "2",
            order: { xs: 3, sm: 2, md: 2 },
          }}
        />
        
        {pageState.isContentLoading ? (
          <Grid item xs={12} sm={8} md={9} sx={{ order: { xs: 1, sm: 2, md: 3 } }}>
            <ComponentSkeleton />
          </Grid>
        ) : (
          <Grid item xs={12} sm={8} md={9} sx={{ order: { xs: 1, sm: 2, md: 3 } }}>
            <NavigateChapter 
              setIsContentLoading={(loading) => 
                setPageState(prev => ({ ...prev, isContentLoading: loading }))
              } 
            />
            
            {pageState.lessonData.map((content) => (
              <MemoizedContentRenderer 
                key={content.content_id} 
                content={content} 
              />
            ))}
            
            <NavigateChapterBottom 
              setIsContentLoading={(loading) => 
                setPageState(prev => ({ ...prev, isContentLoading: loading }))
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CourseLessonPage;
