import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { createAccountFormSchema } from "config/schemas/formSchema";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { encryptData } from "utilityFunction/encryption&Decryption";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import mtsLogo from "assets/images/mts-logo.png";

const CreateAccount = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);

  //to display jan as the defualt month
  const sixYearsAgoJanuary = dayjs().subtract(6, "year").startOf("year");

  //destructure the data from the hook
  const {
    data: createUserData,
    loading: createUserLoading,
    error: createUserError,
    postData,
  } = usePostRequest(API_ENDPOINTS.CREATE_USER_API);

  //for google one tap login
  const {
    data: googleLoginData,
    loading: googleLoginLoading,
    error: googleLoginError,
    postData: googlePayload,
  } = usePostRequest(API_ENDPOINTS.CREATE_USER_USING_GOOGLE_API);

  const onSubmit = async (values, actions) => {
    const formattedValues = {
      ...values,

      //converting the dob to the format DD-MM-YYYY and to string
      dateOfBirth: values.dateOfBirth
        ? dayjs(values.dateOfBirth).format("DD-MM-YYYY").toString()
        : null,
    };
    console.log(
      "values from get request in create account form",
      formattedValues
    );

    try {
      // Encrypt the first name last name date of birth and password and password
      const encryptedfirstName = encryptData(formattedValues.firstName.trim().toLowerCase());
      const encryptedlastName = encryptData(formattedValues.lastName.trim().toLowerCase());
      const encryptedEmail = encryptData(formattedValues.email.trim().toLowerCase());
      const encryptedPassword = encryptData(formattedValues.password.trim());
      const encryptedDOB = encryptData(formattedValues.dateOfBirth);

      //send post req payload to create new account
      await postData({
        username: "",
        email: encryptedEmail,
        first_name: encryptedfirstName,
        last_name: encryptedlastName,
        date_of_birth: encryptedDOB,
        password_hash: encryptedPassword,
        contact_number: "",
      });
    } catch (err) {
      console.log("error while creating account", err);
    }
    if (!createUserLoading && createUserData) {
      actions.resetForm();
    }
  };

  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    //local state
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      email: "",
      password: "",
      confirmPassword: "",
    },
    //validate the email and password( client side validation)
    validationSchema: createAccountFormSchema,
    //onSubmit handler
    onSubmit,
  });

  //for google one tap login

  const oneTapLogin = useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      // console.log(credentialResponse);
      // Destructuring
      const { credential } = credentialResponse;
      if (credential) {
        try {
          await googlePayload({ tokenId: credential });
        } catch (error) {
          console.error("Error sending Google credential to server:", error);
        }
      } else {
        console.error("No credential received from Google login");
      }
    },
    onError: (error) => console.log("Google One Tap Login Error:", error),
  });

  // Use an effect to handle the response after loading is complete
  useEffect(() => {
    if (!googleLoginLoading && googleLoginData) {
      if (googleLoginData.user_id && googleLoginData.token) {
        // Store the user ID and token in local storage
        localStorage.setItem("user_id", googleLoginData.user_id);
        localStorage.setItem("token", googleLoginData.token);

        // console.log(
        //   "Google login successful. User data stored in local storage."
        // );
        notifyUser(
          "Login Successful",
          "You have successfully created account with MTS LMS.",
          "success"
        );
        // navigate("/app/dashboard");
        window.location.href = "/app/dashboard";
      } else {
        console.error("Received incomplete data from Google login API");
      }
    } else if (googleLoginError) {
      console.error("Error during Google login:", googleLoginError);
    }
  }, [googleLoginLoading, googleLoginData]);

  // footer text function
  function Copyright(props) {
    return (
      <Typography
        variant="caption"
        align="center"
        className="footer-text-create-account"
        {...props}
      >
        {"Copyright ©2024 "}
        <Link
          className="text my-true-skill"
          to="https://mytrueskills.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          My True Skills
        </Link>{" "}
      </Typography>
    );
  }
  useEffect(() => {
    if (!createUserLoading) {
      if (createUserData) {
        localStorage.setItem("user_id", createUserData.user_id);
        localStorage.setItem("token", createUserData.token);
        // console.log("create user data :", createUserData);
        notifyUser(
          "Account Created",
          "Your account has been successfully created.",
          "success"
        );
        // navigate("/login");
        // navigate("/app/dashboard");
        window.location.href = "/app/dashboard";
      } else {
        if (createUserError) {
          notifyUser(
            "Registration Failed",
            "Please try a different email address.",
            "error"
          );
          // console.log("create user data not valid", createUserError);
        }
      }
    }
  }, [createUserData, navigate, createUserError]);

  return (
    <Box className="auth-form-wrapper create-account-wrapper">
      <Box className="auth-form-card create-account-card">
        <Box className="login-form create-account-card__form">
          <img
            src={mtsLogo}
            alt="logo"
            className="login-card-logo create-account-logo"
          />
          <Typography
            component="h1"
            variant="h5"
            className=" text header-text header-text-create-account"
          >
            Create Account
          </Typography>
          <Typography
            variant="h6"
            mb={1}
            className="text header-caption header-caption-create-account"
          >
            Start Learning with MTS by creating an account
          </Typography>
          <Box mt={2} component="form" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                  First Name
                </InputLabel>
                <TextField
                  placeholder="John"
                  size="small"
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  type="text"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.firstName && touched.firstName ? (
                  <Typography className="error-input_login">
                    {errors.firstName}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                  Last Name
                </InputLabel>
                <TextField
                  placeholder="Doe"
                  size="small"
                  required
                  fullWidth
                  id="lastName"
                  name="lastName"
                  type="text"
                  autoComplete="family-name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.lastName && touched.lastName ? (
                  <Typography className="error-input_login">
                    {errors.lastName}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                  Date of Birth
                </InputLabel>
                <DatePicker
                  format="DD/MM/YYYY"
                  disableFuture
                  referenceDate={sixYearsAgoJanuary}
                  views={["year", "month", "day"]}
                  disableHighlightToday
                  maxDate={dayjs().subtract(6, "year").startOf("day")}
                  value={values.dateOfBirth}
                  onChange={(value) => {
                    setFieldValue("dateOfBirth", value);
                    setFieldTouched("dateOfBirth", true, false);
                  }}
                  onBlur={() => setFieldTouched("dateOfBirth", true)}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      required: true,
                      error: touched.dateOfBirth && Boolean(errors.dateOfBirth),
                      InputProps: {
                        style: {
                          borderRadius: "10px",
                          color: "#667085",
                          fontFamily: "Gilroy-Regular, sans-serif",
                        },
                      },
                    },
                  }}
                />
                {errors.dateOfBirth && touched.dateOfBirth ? (
                  <Typography className="error-input_login">
                    {errors.dateOfBirth}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                  Email
                </InputLabel>
                <TextField
                  placeholder="john@gmail.com"
                  size="small"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.email && touched.email ? (
                  <Typography className="error-input_login">
                    {errors.email}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                  Password
                </InputLabel>
                <TextField
                  placeholder="••••••••"
                  sx={{ overflow: "hidden" }}
                  size="small"
                  name="password"
                  required
                  fullWidth
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disableRipple
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ mr: 2, width: "50%" }} />
                          ) : (
                            <Visibility sx={{ mr: 2, width: "50%" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && touched.password ? (
                  <Typography className="error-input_login">
                    {errors.password}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className=" text input-field-label-create-account input-field-label-auth">
                  Confirm Password
                </InputLabel>
                <TextField
                  placeholder="••••••••"
                  size="small"
                  required
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  autoComplete="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <Typography className="error-input_login">
                    {errors.confirmPassword}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              className="button button-create-account"
            >
              Create Account
            </Button>
            <Grid container justifyContent="center" mt={2}>
              <Grid item>
                <Typography className="create-account">
                  Already have an account?
                  <Link to="/login">Login</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
};

export default CreateAccount;
