import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Modal, Typography, Button, Box } from "@mui/material";
import Navbar from "components/common/navbar/Navbar";
import Footer from "components/common/footer/Footer";

const PublicLayout = () => {
  return (
    <Container
      maxWidth={false} className="app-layout__container"
    >
      <Box className="app-layout-navbar">
        <Navbar />
      </Box>
      <Box className="page-body" mt={2}>
        <Outlet />
      </Box>
      <Footer />
    </Container>
  );
};

export default PublicLayout;
