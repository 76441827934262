import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
import QuizFeedback from "./QuizFeedback";
import Slider from '@mui/material/Slider';
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizTimer from "./TimerCard";
import QuizResultCard from "./QuizResultCard";

const RangeQuizSlider = ({ content }) => {
  const { notifyUser } = useContext(NofityUserContext);
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);
  const { postData: saveOptionQuizResponse } = usePostRequest(API_ENDPOINTS.SAVE_OPTION_QUIZ_PROGRESS);

  const [quizSubmittedStatus, setQuizSubmittedStatus] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [display, setLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  const {
    data: createOrderData,
    error: createOrderError,
    loading: loadingCreateOrder,
    postData: postLongQuizAnswer,
  } = usePostRequest(API_ENDPOINTS.SAVE_LONG_ANSWER_QUIZ);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(item => item.content_id === content);

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails) {
      setQuizData(contentDetails[currentQuestionIndex]);

      // If there's already a selected value for this question, update the slider
      if (selectedAnswers[contentDetails[currentQuestionIndex].question_id]) {
        setValue(selectedAnswers[contentDetails[currentQuestionIndex].question_id]);
      } else {
        setValue(1); // Default value for the slider
      }
    }
  }, [contentDetails, currentQuestionIndex]);

  useEffect(() => {
    if (contentDetails && contentDetails.length > 0) {
      const firstQuestion = contentDetails[0];
      setEstimatedCompletionTime(firstQuestion.estimated_completion_time);
    }
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails[0].quiz_la_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);



useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      // console.log("quizStatDetails---> ", quizStatApiResponse);
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_la_id === detail.response_la_id
        );
        
        // Ensure MasterQuizLongAnswer and MasterQuestion are defined
        const question = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.question
          : null;
        const questionScore = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.score
          : null;
  
        return {
          id: detail.response_la_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: questionScore,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
      setModalData(combinedData);
      // console.log("combinedData---> range", combinedData);
    }
  }, [quizStatApiResponse]);


  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };

  const [value, setValue] = useState(1);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);

    // Store the slider value in selectedAnswers for the current question
    const questionId = quizData?.question_id;
    if (questionId) {
      setSelectedAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: newValue,
      }));
    }
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const handleNavigation = (direction) => {
    if (contentDetails) {
      const totalQuestions = contentDetails.length;

      // Only check for selection when navigating forward (next)
      if (direction === "next" && !selectedAnswers[quizData.question_id]) {
        notifyUser("Select range", "Please select a number", "warning");
        return;
      }

      // Determine the new question index based on the direction
      let newQuestionIndex = direction === "next"
        ? currentQuestionIndex + 1
        : currentQuestionIndex - 1;

      // Ensure the new question index is within valid bounds
      newQuestionIndex = Math.max(0, Math.min(newQuestionIndex, totalQuestions - 1));

      // Set the new question index
      setCurrentQuestionIndex(newQuestionIndex);
    }
  };


  const handleSave = async () => {
    setIsSaving(true);
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);

    for (const [questionId, selectedAnswer] of Object.entries(selectedAnswers)) {
      const quizItem = contentDetails.find(item => item.question_id === parseInt(questionId));

      const payload = {
        course_id: lessonIdentifiers.course_id,
        quiz_id: quizItem.question_id,
        answer_text: selectedAnswer.toString(),
        quiz_la_id: quizItem.quiz_la_id,
        completion_time:timeTakenInMinutes,
      };
      try {
        await postLongQuizAnswer(payload);
        setQuizSubmittedStatus(true);
      } catch (error) {
        console.error(`Failed to submit response for question ${quizItem.question_id}`, error);
      }
    }

    notifyUser("Quiz Submitted", "Your responses have been saved successfully!", "success");
  };

  useEffect(() => {
    if (quizSubmittedStatus) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails[0].quiz_la_id, lessonIdentifiers?.course_id);
      const timer = setTimeout(() => {
        setQuizSubmittedStatus(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmittedStatus]);

  if (quizSubmittedStatus) {
    return <QuizFeedback success={true} />;
  }

  const handleTimeUp = async () => {
    setSelectedAnswers({});
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
    data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizLongAnswer.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return  <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizLongAnswer
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
  }

  return display ? (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box className="SAQ_wrapper" sx={{ borderTop: "170px solid #e4efff !important" }}>

          <Box className="SAQ_wrapper__Question-box">
            <Box className="SAQ_wrapper__Question-box--number">
              <Typography className="SAQ_wrapper__Question-box--number-text">
                Question {currentQuestionIndex + 1} / {contentDetails.length}
              </Typography>
            </Box>
            <Typography className="SAQ_wrapper__Question-box--question">
              {quizData.question}
            </Typography>
          </Box>

          <Box className="SAQ_wrapper__answer-wrapper">
            <Box className="SAQ_wrapper__options-wrapper">
              <Grid container spacing={4} justifyContent="center" alignItems="center">
                {estimatedCompletionTime && (
                  <QuizTimer key={timerKey} estimatedCompletionTime={estimatedCompletionTime} handleComplete={handleTimeUp}/>
                )}
                <Grid item xs={12}>
                  <Typography variant="text" className="SAQ_wrapper__guide">
                    Select the number in the range.
                  </Typography>
                </Grid>
                {quizData.is_range_input ? (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Slider
                      aria-label="Range Input"
                      value={value}
                      onChange={handleSliderChange}
                      step={1}
                      marks={[
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9' },
                        { value: 10, label: '10' },
                      ]}
                      min={1}
                      max={10}
                      valueLabelDisplay="auto"
                    />

                  </Grid>
                ) : (
                  <Typography>Select a non-range option</Typography>
                )}
              </Grid>
            </Box>

            <Box className="SAQ_wrapper-navigate" id="no-bg">
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                id="white-bg"
                onClick={() => handleNavigation("previous")}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
              {currentQuestionIndex === contentDetails.length - 1 ? (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={handleSave}
                >
                 {isSaving ? 'Saving...' : 'Save'}
                </Button>
              ) : (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={() => handleNavigation("next")}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>

          <LinearProgress
            className="SAQ_wrapper__progress"
            variant="determinate"
            value={((currentQuestionIndex + 1) / contentDetails.length) * 100}
          />
        </Box>
      )}
    </>

  ) : (
    <Typography>Loading quiz data...</Typography>
  );
};

export default RangeQuizSlider;
