import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Typography, Paper, Button, Box } from "@mui/material";
import Rs1125 from "../../../assets/payment/Rs1125.jpeg";
import Rs1305 from "../../../assets/payment/Rs1305.jpeg";
import Rs1799 from "../../../assets/payment/Rs1799.jpeg";
import { useMediaQuery } from "@mui/material";
import UserContext from "context/userContext/UserContext";
import { useParams } from "react-router-dom";

const GetLivepaymentChart = () => {
    const { userData } = useContext(UserContext);
    const storedPrice = localStorage.getItem('coursePrice');
    const [selectedQRCode, setSelectedQRCode] = useState("Rs1125");
    const [price, setPrice] = useState('');
    const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');

    const { username } = useParams();


    // Set the price from local storage if available
    useEffect(() => {
        if (storedPrice) {
            const parsedPrice = parseInt(storedPrice, 10);
            setPrice(parsedPrice);
            // Optionally, set the selected QR code based on the price
            switch (parsedPrice) {
                case 1125:
                    setSelectedQRCode("Rs1125");
                    break;
                case 1305:
                    setSelectedQRCode("Rs1305");
                    break;
                case 1799:
                    setSelectedQRCode("Rs1799");
                    break;
                default:
                    setSelectedQRCode("Rs1125");
            }
        }
    }, [storedPrice]);

    // Function to return the appropriate QR code image
    const getQRCode = () => {
        switch (selectedQRCode) {
            case "Rs1125":
                return <img src={Rs1125} alt="Payment Image Rs1125" style={{ maxWidth: "100%", height: "70%" }} />;
            case "Rs1305":
                return <img src={Rs1305} alt="Payment Image Rs1305" style={{ maxWidth: "100%", height: "70%" }} />;
            case "Rs1799":
                return <img src={Rs1799} alt="Payment Image Rs1799" style={{ maxWidth: "100%", height: "70%" }} />;
            default:
                return <img src={Rs1125} alt="Payment Image Rs1125" style={{ maxWidth: "100%", height: "70%" }} />;
        }
    };
    const getButtonCode = () => {
        switch (selectedQRCode) {
            case "Rs1125":
                return <Button
                    sx={{
                        backgroundColor: '#2b3d4f',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '2vh 4vh',
                        '&:hover': {
                            color: '#2b3d4f',
                            backgroundColor: '#fff',
                            border: '1px solid #2b3d4f'
                        },
                    }}
                    variant="contained"
                    component="a"
                    href="upi://pay?pa=suparna.prakash@okicici&pn=Suparna%20Shetty&am=1125.00&cu=INR&aid=uGICAgIDVj5X8KQ"

                >
                    Pay Now
                </Button>;
            case "Rs1305":
                return <Button
                    sx={{
                        backgroundColor: '#2b3d4f',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '2vh 4vh',
                        '&:hover': {
                            color: '#2b3d4f',
                            backgroundColor: '#fff',
                            border: '1px solid #2b3d4f'
                        },
                    }}
                    variant="contained"
                    component="a"
                    href="upi://pay?pa=suparna.prakash@okicici&pn=Suparna%20Shetty&am=1305.00&cu=INR&aid=uGICAgIDVj5X8KQ"

                >
                    Pay Now
                </Button>;
            case "Rs1799":
                return <Button
                    sx={{
                        backgroundColor: '#2b3d4f',
                        color: '#fff',
                        borderRadius: '30px',
                        padding: '2vh 4vh',
                        '&:hover': {
                            color: '#2b3d4f',
                            backgroundColor: '#fff',
                            border: '1px solid #2b3d4f'
                        },
                    }}
                    variant="contained"
                    component="a"
                    href="upi://pay?pa=suparna.prakash@okicici&pn=Suparna%20Shetty&am=1799.00&cu=INR&aid=uGICAgIDVj5X8KQ"

                >
                    Pay Now
                </Button>;
            default:
                return <img src={Rs1125} alt="Payment Image Rs1125" style={{ maxWidth: "100%", height: "auto" }} />;
        }
    };

    useEffect(() => {
        // Check if user details are available
        if (username) {
            window.Tawk_API = window.Tawk_API || {};
            window.Tawk_API.visitor = {
                name: username,
            };
        }


        // Add the Tawk.to script
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = 'https://embed.tawk.to/673862314304e3196ae382b3/1icq3v56p';
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Container style={{ padding: "10px", }}>
            <Paper elevation={3} style={{ padding: "30px", marginTop: "50px" }}>
                <Grid container spacing={2}>
                    {/* Left Column: QR Code */}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",  
                            height: "70%"  
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {getQRCode()}
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "3vh" }}>
                            {isMobileOrTablet && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',  
                                    }}
                                >
                                    {getButtonCode()}
                                </Box>
                            )}
                        </div>
                    </Grid>


                    {/* Right Column: Payment Guidelines */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Payment Guidelines
                        </Typography>
                        <Typography variant="body1" paragraph>
                            1. Use any QR code scanner or a payment app of your choice.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2. Point your device camera at the QR code provided to initiate the payment.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            3. Finalize the transaction and note down the transaction ID or take a screenshot for reference.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            4. Send the transaction ID (or the screenshot) in this chat for verification.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            5. Once verified, we’ll notify you, and the course will be successfully enrolled in your profile.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default GetLivepaymentChart;
