import CryptoJS from "crypto-js";

const algorithm = "AES";
const uniqueKey = "909398132182543";
const secretKey = "344677&65$2ec439c4e13NPES*$^!901";

const generateIV = (uniqueKey) => {
  const hash = CryptoJS.SHA256(uniqueKey);
  return CryptoJS.enc.Hex.parse(hash.toString().slice(0, 32));
};

export const encryptData = (rawData, uniqueKey='909398132182543') => {
  let data = typeof rawData === "string" ? rawData : rawData.toString();

  const iv = generateIV(uniqueKey);
  const key = CryptoJS.enc.Utf8.parse(secretKey);

  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted.toString();
};

export const decryptData = (encryptedData, uniqueKey='909398132182543') => {
  const iv = generateIV(uniqueKey);
  const key = CryptoJS.enc.Utf8.parse(secretKey);

  const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

// Example usage
// const plainText = "John";
// const encryptText = encryptData(plainText, uniqueKey);
// console.log("Plain text:", plainText);
// console.log("Encrypted:", encryptText);
// const decryptText = decryptData(encryptText, uniqueKey);
// console.log("Decrypted:", decryptText);
