import React, { useContext } from "react";
import ReactPlayer from "react-player";

const VideoPlayerPublic = ({ videoUrl, thumbnailUrl }) => {

  return (
    <ReactPlayer
      className="react-player"
      url={videoUrl}
      style={{ borderRadius: "15px" }}
      width="100%"
      height="100%"
      light={
        <img
          src={thumbnailUrl}
          alt="Thumbnail"
          className="react-player__preview--image"
        />
      }
      config={{
        youtube: {
          playerVars: {
            rel: 0,            // Disable related videos
            showinfo: 0,       // Hide video title and player actions
            modestbranding: 1  // Hide YouTube logo
          }
        }
      }}
      controls={true}
    />
  );
};

export default VideoPlayerPublic;
