import { createContext, useState } from "react";

const NofityUserContext = createContext();

export default NofityUserContext;
export const NofityUserProvider = ({ children }) => {
  const [message, setMessage] = useState({
    title: null,
    caption: null,
    type: null,
  });

  const notifyUser = (title, caption, type) => {
    setMessage({ title, caption, type });
  };

  const clearUserNotification = () => {
    setMessage(null);
  };
  return (
    <NofityUserContext.Provider
      value={{ message, notifyUser, clearUserNotification }}
    >
      {children}
    </NofityUserContext.Provider>
  );
};
