import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const LinearProgressWithLabel = ({ value }) => {
  return (
    <Box className="progress-wrapper">
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          className="course-chapter-progress-wrapper__progress"
          value={value}
        />
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const CourseCompletionProgressBar = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};

CourseCompletionProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default CourseCompletionProgressBar;
