import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AppContext from "context/appContext/AppContext";
import { API_ENDPOINTS } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import NotifyUserContext from "context/notifyUserContext/NotifyUserContext";

const ReportContentForm = () => {
  const { currentUrl, setCurrentUrl } = useContext(AppContext);
  const { notifyUser } = useContext(NotifyUserContext);
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(""); // To store selected option in Step 1
  const [selectedOptionStep2, setSelectedOptionStep2] = useState("");
  const [selectedOptionContent, setSelectedOptionContent] = useState(""); // To store content type for formData
  const [somethingElseInput, setSomethingElseInput] = useState(""); // Input for 'Something else'
  const [error, setError] = useState(""); // Step validation error
  const [urlError, setUrlError] = useState(""); // URL validation error
  const [url, setUrl] = useState(""); // URL state
  const [message, setMessage] = useState(""); // Message state
  const [messageError, setMessageError] = useState('');
  const [file, setFile] = useState(null); // File state
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');

  const progress = step * 33.33;

  const {
    data: saveData,
    error: saveDataError,
    loading: loadingSaveData,
    postData: postReportData,
  } = usePostRequest(API_ENDPOINTS.SAVE_REPORT_RAISE);

  useEffect(() => {
    if (currentUrl) {
      setUrl(currentUrl);
    }
  }, [currentUrl]);

  useEffect(() => {
    if (!loadingSaveData && saveData && saveData.message) {
      setCurrentUrl('');
      navigate('/app/my-course');
      notifyUser("Success!", "Your report has been filed. You will be notified!", "success");
    }
  }, [loadingSaveData, saveData, navigate, notifyUser]);

  const handleNextStep = () => {
    if (step === 1 && selectedOption === "") {
      setError("Please select a content type to continue.");
      return;
    }

    if (step === 2) {
      if (selectedOptionStep2 === "") {
        setError("Please select a reason to continue.");
        return;
      } else if (selectedOptionStep2 === "Something else" && somethingElseInput.trim() === "") {
        setError("Please specify your reason.");
        return;
      }
    }

    if (step === 3) {
      if (url.trim() === "") {
        setError("URL field cannot be empty.");
        return;
      } else if (message.trim() === "") {
        setError("Message field cannot be empty.");
        return;
      }
    }

    setError(""); // Reset error state
    setStep(step + 1); // Move to the next step
  };

  const handlePreviousStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSave = () => {
    if (url.trim() === "") {
      setUrlError("URL field cannot be empty.");
      return;
    }

    setUrlError(""); // Reset URL error if valid
    if (!message.trim()) {
      setMessageError('Message is required');
      return;
    } else {
      setMessageError('');
    }
    const formData = new FormData();
    formData.append("contentType", selectedOptionContent); // Step 1 content type
    formData.append("reason", somethingElseInput || selectedOptionStep2); // Step 2 reason
    formData.append("url", url); // Step 3 URL
    formData.append("message", message); // Step 3 message

    if (file) {
      formData.append("file", file);
    }

    postReportData(formData); // API call to submit the report
  };

  const handleOptionChangeStep1 = (event) => {
    setSelectedOption(event.target.value);
    setSelectedOptionContent(event.target.value);
    setError(""); // Clear error on valid selection
  };

  const handleOptionChangeStep2 = (event) => {
    setSelectedOptionStep2(event.target.value);
    if (event.target.value !== "Something else") {
      setSomethingElseInput(""); // Reset input for 'Something else'
    }
    setError(""); // Clear error on valid selection
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
    if (event.target.value.trim() !== "") {
      setUrlError(""); // Remove error when input is valid
    }
  };
  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      handleFileChange(event); // Call the passed `handleFileChange` prop function if needed
    }
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Card className="report-content-card">
      <CardContent>
        {step === 1 && (
          <>
            <Typography variant="h6" className="report-content-card__question">
              What type of content are you reporting?
            </Typography>
            <RadioGroup value={selectedOption} onChange={handleOptionChangeStep1} className="report-content-card__radio-group">
              <FormControlLabel
                value="image"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      Image
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="video"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      Video
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="LMS Content"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      LMS Content
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
            {error && (
              <Typography
                variant="body2"
                color="error"
                className="report-content-card__error-message"
              >
                {error}
              </Typography>
            )}
            <Button
              variant="outlined"
              className="report-content-card__form-control--button"
              onClick={handleNextStep}
              disabled={selectedOption === ""} // Disable button if no option is selected
            >
              Continue
            </Button>
            <Box className="report-content-card__progress-status" sx={{ width: `${progress}%`, transition: "width 0.3s ease" }} />
          </>
        )}

        {step === 2 && (
          <>
            <Typography variant="h6" component="div" className="report-content-card__question">
              Why are you reporting this content?
            </Typography>
            <RadioGroup value={selectedOptionStep2} onChange={handleOptionChangeStep2} className="report-content-card__radio-group">
              <FormControlLabel
                value="Incorrect labeling"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      Incorrect labeling
                    </Typography>
                    <Typography
                      variant="body2"
                      className="report-content-card__form-control-activity-type"
                    >
                      Inappropriate or irrelevant keywords, names, or titles.
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="Intellectual property infringement"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      Intellectual property infringement
                    </Typography>
                    <Typography
                      variant="body2"
                      className="report-content-card__form-control-activity-type"
                    >
                      Content that infringes copyright, trademark or other rights.
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="Graphic violence"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      Graphic violence
                    </Typography>
                    <Typography
                      className="report-content-card__form-control-activity-type"
                      variant="body2"
                    >
                      Violent or triggering content or promotion of violence or self-harm.
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="Something else"
                control={<Radio />}
                label={
                  <Box className="report-content-card__form-control">
                    <Typography
                      className="report-content-card__form-control-activity"
                      variant="body1"
                    >
                      Something else
                    </Typography>
                  </Box>
                }
              />
              {selectedOptionStep2 === "Something else" && (
                <TextField
                  fullWidth
                  label="Please specify"
                  value={somethingElseInput}
                  onChange={(e) => setSomethingElseInput(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  className="report-content-card__text-field"
                  required
                />
              )}
            </RadioGroup>
            {error && (
              <Typography
                variant="body2"
                color="error"
                className="report-content-card__error-message"
              >
                {error}
              </Typography>
            )}
            <Box className="report-content-card__button-wrapper">
              <Button
                variant="outlined"
                className="report-content-card__form-control--button--btn"
                onClick={handlePreviousStep}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className="report-content-card__form-control--button--btn"
                onClick={handleNextStep}
                id="contained"
                disabled={selectedOptionStep2 === ""} // Disable button if no option is selected
              >
                Continue
              </Button>
            </Box>
            <Box className="report-content-card__progress-status" sx={{ width: `${progress}%`, transition: "width 0.3s ease" }} />
          </>
        )}

        {step === 3 && (
          <>
            <Typography variant="h6" component="div" className="report-content-card__question">
              Tell us more about this content
            </Typography>
            <Box component="form" mt={2} noValidate className="report-content-card__form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className="report-content-card__input-label">Reported Content URL Field</InputLabel>
                  <TextField
                    className="report-content-card__text-field"
                    size="small"
                    fullWidth
                    required
                    id="url"
                    name="url"
                    type="url"
                    autoComplete="url"
                    InputProps={{
                      style: {
                        borderColor: "#D0D5DD",
                        borderRadius: "8px",
                      },
                    }}
                    value={url}
                    onChange={handleUrlChange}
                    error={!!urlError}
                    helperText={urlError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className="report-content-card__input-label">Message</InputLabel>
                  <TextField
                    size="small"
                    id="message"
                    variant="outlined"
                    label="Message"
                    fullWidth
                    required
                    name="message"
                    autoComplete="message"
                    multiline
                    rows={4}
                    InputProps={{
                      style: {
                        borderColor: "#D0D5DD",
                        borderRadius: "8px",
                      },
                    }}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      if (e.target.value.trim()) {
                        setMessageError(''); // Clear error when input is valid
                      }
                    }}
                    error={!!messageError}
                    helperText={messageError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Attach Files</InputLabel>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #D0D5DD"
                    borderRadius="8px"
                    padding="10px"
                    height="150px"
                  >
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                      Drop your files
                    </Typography>
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{
                        borderColor: '#3b5269',
                        borderRadius: '30px',
                        padding: '1vh 6vh',
                        color: '#3b5269', // Optional: ensures the text color matches the border
                        '&:hover': {
                          borderColor: '#3b5269',
                          backgroundColor: 'rgba(59, 82, 105, 0.1)', // Optional hover effect
                        },
                      }}
                    >
                      Choose Files
                      <input type="file" hidden onChange={onFileChange} />
                    </Button>
                    {fileName && (
                      <Typography variant="body2" color="textPrimary" sx={{ marginTop: '10px' }}>
                        Selected File: {fileName}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  className="report-content-card__error-message"
                >
                  {error}
                </Typography>
              )}
              <Box mt={2} className="report-content-card__button-wrapper">
                <Button
                  variant="outlined"
                  className="report-content-card__form-control--button--btn"
                  onClick={handlePreviousStep}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  className="report-content-card__form-control--button--btn"
                  id="contained"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </Box>
              <Box className="report-content-card__progress-status" sx={{ width: `${progress}%`, transition: "width 0.3s ease" }} />
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ReportContentForm;
