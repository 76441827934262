

import React from 'react';
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const QuizTimerStartOverlay = ({ handleStartQuiz }) => {
  return (
    <Box className="quiz-start-overlay">
    <Typography variant="h5" gutterBottom>
      Ready to start the quiz?
    </Typography>
    <Button
      className="navigate-chapter__button"
      variant="outlined"
      color="primary"
      onClick={handleStartQuiz}
    >
      Start Quiz
    </Button>
    </Box>
    
  
  );
};

export default QuizTimerStartOverlay;
