import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseDetailsContext from "context/courseDetailsContext/CourseDetailsContext";
import { Box, Button, Typography } from "@mui/material";
import paymentFailure from "assets/icons/payment-failure.svg";
// import { API_ENDPOINTS } from "config/ApiConfig";
// import useGetRequest from "hooks/useGetRequest";

const PaymentFailurePage = () => {
  const { courseSlug } = useContext(CourseDetailsContext);
  const [timer, setTimer] = useState(30);
  const [slug, setSlug] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (courseSlug) {
      setSlug(courseSlug);
    }
  }, [courseSlug]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          handleRetryPayment();
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const handleRetryPayment = () => {
    // Navigate back to the previous page immediately
    if (slug) {
      navigate(`/app/course/${slug}`);
    }
  };
  return (
    <Box className="payment">
      <Box
        component="img"
        src={paymentFailure}
        alt="payment success"
        className="payment__image"
      />
      <Typography className="payment__title">Payment Failed!</Typography>
      <Box className="payment__description-container">
        <Typography className="payment__description">
          Unfortunately payment was rejected, seems like there was some trouble.
        </Typography>
        <Typography className="payment__description">
          We are there with you, just hold back.
        </Typography>
      </Box>
      <Button
        variant="outlined"
        className="payment__button"
        onClick={handleRetryPayment}
      >
        Retry Payment
      </Button>
      <Typography className="payment__redirect-text">
        Page will be automatically redirected to the course page in {timer}{" "}
        Seconds
      </Typography>
    </Box>
  );
};

export default PaymentFailurePage;
