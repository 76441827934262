import { createContext, useState, useEffect } from "react";

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  const [currentUrl, setCurrentUrl] = useState(""); 


  return (
    <AppContext.Provider value={{ currentUrl, setCurrentUrl }}>
      {children}
    </AppContext.Provider>
  );
};
