import React, { useEffect, useRef, useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import CourseOverviewCard from "components/module/app/courseDetails/CourseOverviewCard";
import CurriculumCard from "components/module/app/courseDetails/CurriculumCard";
import FAQCard from "components/module/app/courseDetails/FAQCard";
import CourseReview from "components/module/app/courseDetails/CourseReview";

const CourseContent = () => {
  const overviewRef = useRef();
  const curriculumRef = useRef();
  const faqRef = useRef();
  const reviewRef = useRef();

  const [value, setValue] = useState(0);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === overviewRef.current) setValue(0);
          else if (entry.target === curriculumRef.current) setValue(1);
          else if (entry.target === faqRef.current) setValue(2);
          else if (entry.target === reviewRef.current) setValue(3);
        }
      });
    }, options);

    observer.observe(overviewRef.current);
    observer.observe(curriculumRef.current);
    observer.observe(faqRef.current);
    observer.observe(reviewRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const scrollHandler = (elementRef) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const tabStyle = {
    "&.MuiTabs-indicator": {
      backgroundColor: "#39CCCC !important",
    },
    "&.Mui-selected": {
      color: "#39CCCC",
    },
    "&.MuiTab-root::after": {
      content: '""',
      position: "absolute",
      right: 0,
      top: "20%",
      height: "60%",
      width: "1px",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
    "&:last-child::after": {
      display: "none",
    },
  };

  return (
    <Box>
      <Box
        className="course-content-tab-wrapper"
        sx={{ borderColor: "divider" }}
      >
        <Tabs
          className="course-content-tab-wrapper__tabs"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#39CCCC !important",
              color: "#39CCCC !important",
              height: "2px  !important",
              opacity: "0.6",
            },
          }}
          aria-label="course content tabs"
          variant="fullWidth"
        >
          <Tab
            onClick={() => scrollHandler(overviewRef)}
            label="Overview"
            className="course-content-tab-wrapper__tabs--tab"
            sx={tabStyle}
          />
          <Tab
            onClick={() => scrollHandler(curriculumRef)}
            label="Curriculum"
            className="course-content-tab-wrapper__tabs--tab"
            sx={tabStyle}
          />
          <Tab
            onClick={() => scrollHandler(faqRef)}
            label="FAQs"
            className="course-content-tab-wrapper__tabs--tab"
            sx={tabStyle}
          />
          <Tab
            onClick={() => scrollHandler(reviewRef)}
            label="Reviews"
            className="course-content-tab-wrapper__tabs--tab"
            sx={tabStyle}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div ref={overviewRef}>
          <CourseOverviewCard />
        </div>
        <div ref={curriculumRef}>
          <CurriculumCard />
        </div>
        <div ref={faqRef}>
          <FAQCard />
        </div>
        <div ref={reviewRef}>
          <CourseReview />
        </div>
      </Box>
    </Box>
  );
};

export default CourseContent;
