import { Box, Skeleton } from "@mui/material";
import React from "react";

const VideoPlayerSkeleton = () => {
  return (
    <Box sx={{ width: "100%", maxWidth: 800, margin: "auto", borderRadius: "10px" }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        sx={{ paddingTop: "56.25%", borderRadius: "22px" }}
      />
    </Box>
  );
};

export default VideoPlayerSkeleton;
