import React from "react";
import { Box, Typography } from "@mui/material";

const ReportContentHeader = () => {
  return (
    <Box class="report-content-header" component={"div"}>
      <Typography
        variant="h4"
        component="div"
        className="report-content-header__title"
      >
        Report Content
      </Typography>
      <Typography
        variant="body1"
        component="div"
        className="report-content-header__text"
      >
        Found something on MTS LMS that doesn’t seem right?
        <Typography
          component="span"
          display="block"
          className="report-content-header__text"
          id="caption"
        >
          Help us keep MTS safe for everyone by reporting it
        </Typography>
      </Typography>
    </Box>
  );
};

export default ReportContentHeader;
